import React, {
  FC,
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/es/typography';
import { SizeType } from 'antd/es/config-provider/SizeContext';

import {
  Row,
  Col,
  ExpandIcon,
  Panel,
  Collapse,
} from 'app-wrapper/view/components';
import { CarrierSCACNamesRFQ, ECarrierSCAC } from 'monetary/constants';
import {
  useWindowSize, useCollapseOnChangeHandler,
} from 'app-wrapper/hooks';
import {
  VerticalFormItemSvg,
} from 'app-wrapper/view/icons';
import themesColors from 'app-wrapper/view/themes/themesColors';

import { CarriersSelect, CarriersWrapper } from './Carriers.styled';

const collapseIcon = (<VerticalFormItemSvg />);

export interface ICarriersComponentProps {
  isAllDisabled?: boolean
  formLocalState: { isCollapsed?: boolean }
  setFormLocalState: React.Dispatch<{ isCollapsed?: boolean }>
  forceCollapse?: boolean
  isCollapsed?: boolean
  carriersValues?: string[]
  isCarriersError?: boolean
  isSubmitVisited?: boolean
  isSubmitClicked?: boolean
}

export const CarriersComponent: FC<ICarriersComponentProps> = ((props) => {
  const {
    formLocalState,
    isAllDisabled,
    forceCollapse,
    isCollapsed,
    carriersValues,
    isSubmitVisited,
    isSubmitClicked,
    isCarriersError,
  } = props;

  const carriersValuesWithFullNaming = carriersValues ? carriersValues.map((carrierType) => CarrierSCACNamesRFQ[carrierType as ECarrierSCAC] || carrierType) : [];

  const { isFullMediaWidth } = useWindowSize();
  const [isResponsive, setIsResponsive] = useState(isFullMediaWidth);
  useEffect(() => {
    if (forceCollapse) {
      setIsResponsive(false);
    } else {
      setIsResponsive(!!formLocalState?.isCollapsed);
    }
  }, [forceCollapse, formLocalState?.isCollapsed]);
  const sizeSelect: SizeType = useMemo(() => (isFullMediaWidth ? 'large' : 'middle'), [isFullMediaWidth]);
  const { t } = useTranslation();

  const headerIsFullComponent = useMemo(() => isResponsive && (
    <>
      <Row className="RFRFormRequest__Row__Containers__content">
        <Col span={10}>
          <div className="RFRFormRequest__Row__Containers__content_title">
            <Typography.Text>{`${''}`}</Typography.Text>
          </div>
        </Col>
        <Col span={1} style={{ minWidth: isFullMediaWidth ? '40px' : '32px' }} />
      </Row>
    </>
  ), [isFullMediaWidth, isResponsive, t]);

  const containerTypeComponent = useCallback(() => (
    <CarriersSelect
      mode="multiple"
      value={carriersValuesWithFullNaming}
      data-class="containerTypeComponent"
      size={sizeSelect}
      placeholder={t('Carriers')}
      disabled={isAllDisabled}
      suffixIcon={collapseIcon}
    />
  ), [
    carriersValuesWithFullNaming,
    isAllDisabled,
    isSubmitVisited,
    sizeSelect,
    t,
  ]);

  const itemCarrierIsFull = useCallback((index) => (
    <Row
      className={`${index === 0 ? 'RFRFormRequest__Row__Containers__parent_content_item_first' : ''} RFRFormRequest__Row__Containers__content RFRFormRequest__Row__Containers__parent_content_item`}
      key={`RFRFormRequest__Row__Containers__parent_content_item_${index}`}
    >
      <Col span={14}>
        {carriersValuesWithFullNaming?.length ? containerTypeComponent() : t('Carriers cannot be defined for this request')}
      </Col>
    </Row>
  ), [
    containerTypeComponent,
    isAllDisabled,
    carriersValuesWithFullNaming,
  ]);

  const itemCarrierIsMedium = useCallback((index) => (
    <Row
      className={`${index === 0 ? 'RFRFormRequest__Row__Containers__parent_content_item_first' : ''} RFRFormRequest__Row__Containers__content RFRFormRequest__Row__Containers__parent_content_item
        RFRFormRequest__Row__Containers__parent_content_item__medium`}
      key={`RFRFormRequest__Row__Containers__parent_content_item_${index}`}
    >
      <Col span={24}>
        <Row>
          <Col span={24}>
            {containerTypeComponent()}
          </Col>
        </Row>
      </Col>
    </Row>
  ), [
    containerTypeComponent,
    isAllDisabled, t,
  ]);

  const containersComponent = useMemo(() => (
    <>
      {isResponsive && (
        <Row justify="space-between" className="RFRFormRequest__Row__col_both__title RFRFormRequest__Row__Containers__temperature">
          <div>
            <Typography.Text>{t('Carriers')}</Typography.Text>
          </div>
        </Row>
      )}

      {headerIsFullComponent}
      {isResponsive ? itemCarrierIsFull(0) : itemCarrierIsMedium(0)}
    </>
  ), [
    carriersValues,
    t,
    headerIsFullComponent,
    isResponsive,
    itemCarrierIsFull,
    itemCarrierIsMedium,
  ]);
  const endVisible = useRef<HTMLElement>(null);

  const [
    changeErrorIsCollapseState, setChangeErrorIsCollapseState,
  ] = useState<Array<string>>([]);

  useEffect(() => {
    if (isSubmitVisited && isCarriersError) {
      setChangeErrorIsCollapseState(['1']);
    }
  }, [isSubmitVisited, isCarriersError, isSubmitClicked]);

  const changeErrorIsCollapseCallback = useCallback(
    () => {
      setChangeErrorIsCollapseState((prev) => (prev.length ? [] : ['1']));
    },
    [],
  );

  const collapseOnChangeHandler = useCollapseOnChangeHandler(
    endVisible.current, changeErrorIsCollapseCallback,
  );

  const collapseComponent = useMemo(() => (
    <Collapse
      expandIcon={({ isActive }) => ExpandIcon({
        isActive,
        color: `${changeErrorIsCollapseState.length ? themesColors.primaryBranding6 : themesColors.neutralBranding7}`,
      })}
      onChange={collapseOnChangeHandler}
      activeKey={changeErrorIsCollapseState}
    >
      <Panel
        header={t('Carriers')}
        key="1"
      >
        {containersComponent}
      </Panel>
    </Collapse>
  ), [changeErrorIsCollapseState, collapseOnChangeHandler, containersComponent, t]);

  return (
    <CarriersWrapper isNotCollapsed={!isCollapsed}>
      <Row className="RFRFormRequest__Row__col_both RFRFormRequest__Row__Containers RFRFormRequest__Row__col__containersPanel">
        <Col span={24}>
          {!isResponsive ? (
            collapseComponent
          ) : (
            containersComponent
          )}
          <span
            id="RFRFormRequest__Row__col__containerssPanel__ref_endVisible"
            ref={endVisible}
          />
        </Col>
      </Row>
    </CarriersWrapper>
  );
});
