import Modal from 'antd/es/modal';
import { BadRequestError, ConflictError } from 'app-wrapper/models/errors';
import {
  EFreightIncoterms,
  EFreightIncotermsByExport,
  EFreightIncotermsByImport,
  EFreightIncotermsTrade,
} from 'monetary/constants';
import { FreightQuotaContentCostDTM } from 'monetary/models/dtm';
import { BaseController, controller } from 'proto/BaseController';
import moment from 'moment/moment';
import { Moment } from 'moment';
import notification from 'antd/es/notification';

import {
  BookingWizardContainersErrorsDTM,
  CargoBaseDTM,
  CargoDTM,
  CargoErrorsDTM,
  CommodityDTM,
  ContainerDocumentDTM,
  HazmatDTM,
  FullCompanyDTM,
  TemperatureControlDTM,
  ShippingPartyDTM,
  ShipmentConfirmationDTM,
  ShipmentAccountStatsCustomer,
  ShippingPartyReference,
  ShipmentReferenceDTM,
  IAddressDTM,
  IContactDTM,
  ContainerDTM,
  LoadingControlDTM, ShipmentPreviewDTM, CompanyDTM,
} from 'shipment-operations/models/dtm';
import { R as monetaryR } from 'monetary/repository';
import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { UC as appUC } from 'app-wrapper/controllers';
import i18n from 'app-wrapper/i18n/i18n';
import { EPickupDeliveryTabs } from 'shipment-operations/view/components/BookingDrayageDrawer/components/PickupDeliveryStep/PickupDeliveryStep.component';
import { EShipmentOrganizationRole } from 'user-management/constants';
import { R as userManagementR } from 'user-management/repository';
import {
  parseDurationFromHoursToDays,
  validateDecimalFraction,
  validationEmail,
  validationPhone,
} from 'app-wrapper/utils';
import { RouteNames, US_COUNTRY_CODE } from 'app-wrapper/constants';
import { ReferenceDTM, ValidationErrorDTM, ValidationErrorType } from 'app-wrapper/types';

import { validateRequiredField } from 'shipment-operations/controllers/CargoController/validateRequiredField';
import { validateReferences } from 'shipment-operations/controllers/CargoController/validateReferences';
import { validateHazmat } from 'shipment-operations/controllers/CargoController/validateHazmat';
import { DateDtm } from 'app-wrapper/models/dtm';
import {
  EShipmentConfirmationTypes,
  EShippingPartyTypes,
  OrganizationPaymentMethod,
  PAYABLES,
  CONTAINER_MAX_COST_VALUE,
  EShipmentLoadingMethod,
  EShipmentReceivingMethod,
  EDrayageLoadingSide, ShipmentStatusEnum,
} from 'shipment-operations/constants';
import { v4 as uuidv4 } from 'uuid';
import { AdditionalServiceCheckedDTM } from 'monetary/models/dtm/Quotas';
import { AdditionalDrawersUseCase } from 'monetary/usecases/AdditionalDrawers.useCase';

const FORBIDDEN_HS_CODE = '000000';

@controller
export class BookingWizardController extends BaseController {
  public getCargos = async (shipmentId: string, hasCargos?: boolean) => {
    const hasTemperatureControl = R.selectors.bookingWizard.getHasTemperatureControl(this.store.getState());
    let cargos: CargoDTM[] = [];
    const alreadyHasCargos = hasCargos || !!R.selectors.bookingWizard.getCargos(this.store.getState()).length;

    cargos = await R.services.cargo.getCargos(+shipmentId);

    cargos = cargos.map((cargo) => ({
      ...cargo,
      code: cargo.code !== FORBIDDEN_HS_CODE ? cargo.code : undefined,
      packageType: alreadyHasCargos ? cargo.packageType : undefined,
    }));

    if (hasTemperatureControl) {
      cargos = cargos.map((cargo) => ({
        ...cargo,
        hsValidationStatus: 'REQUEST_SENT_AND_VALID',
      }));

      const defaultCargo = R.selectors.bookingWizard.getDefaultCargo(this.store.getState());
      const { code, name } = cargos[0];

      this.dispatch(R.actions.bookingWizard.setDefaultCargo(CargoBaseDTM.fromPlain({
        ...defaultCargo,
        code,
        name,
      })));
    }

    if (!cargos.length) {
      this.dispatch(R.actions.bookingWizard.addCargo());
    }

    if (cargos.length) {
      this.dispatch(R.actions.bookingWizard.setCargos(cargos));
    }

    const cargosInStore = R.selectors.bookingWizard.getCargos(this.store.getState());
    const commodityPromises: Promise<CommodityDTM[]>[] = [];

    cargosInStore.forEach(({ code }) => {
      if (!code) {
        return;
      }

      commodityPromises.push(R.services.commodity.getCommodities(code,
        {
          query: code,
          size: 10,
        }));
    });

    const commoditySearchResults = await Promise.all(commodityPromises);

    const commodities = commoditySearchResults.map((commodityList, i) => {
      const commodity = commodityList.find((_commodity) => _commodity.code === cargosInStore[i].code)!;
      if (commodity && commodity.code !== FORBIDDEN_HS_CODE) {
        this.dispatch(R.actions.bookingWizard.setHsCode({
          code: commodity.code,
          name: commodity.name,
          errorsCode: validateRequiredField(commodity.code),
          errorsName: validateRequiredField(commodity.name),
          cargoId: Number(cargosInStore[i]?.id),
        }));
      }
      return commodity;
    }).filter((v) => !!v);

    let temperatureControl: TemperatureControlDTM | null = null;

    if (hasTemperatureControl) {
      temperatureControl = await R.services.temperatureControl.getTemperatureControlData(shipmentId);
    }

    if (temperatureControl) {
      this.dispatch(R.actions.temperatureControl.setTemperatureControlData(temperatureControl));
    }

    this.dispatch(R.actions.commodity.setCommodities(commodities));
  }

  public calculateTotalCost = (
    cost: FreightQuotaContentCostDTM,
    incotermAll: EFreightIncoterms,
    incotermTrade: EFreightIncotermsTrade,
  ) => {
    if (!incotermAll) return '';

    const incoterm = incotermAll as string;

    switch (incotermTrade) {
      case EFreightIncotermsTrade.EXPORT:
        if (incoterm === EFreightIncotermsByExport.DAP || incoterm === EFreightIncotermsByExport.DPU) {
          return `${(cost?.originTotalCost || 0) + (cost?.freightTotalCost || 0) + (cost?.destinationTotalCost || 0)}`;
        }
        if (incoterm === EFreightIncotermsByExport.CIF || incoterm === EFreightIncotermsByExport.CFR) {
          return `${(cost?.originTotalCost || 0) + (cost?.freightTotalCost || 0)}`;
        }

        return '';

      case EFreightIncotermsTrade.IMPORT:
        if (incoterm === EFreightIncotermsByImport.EXW) {
          return `${(cost?.originTotalCost || 0) + (cost?.freightTotalCost || 0) + (cost?.destinationTotalCost || 0)}`;
        }
        if (incoterm === EFreightIncotermsByImport.FCA || incoterm === EFreightIncotermsByImport.FAS || incoterm === EFreightIncotermsByImport.FOB) {
          return `${(cost?.freightTotalCost || 0) + (cost?.destinationTotalCost || 0)}`;
        }

        return '';

      default:
        return '';
    }
  };

  public initDrayage = async (shipmentId: string) => {
    if (!shipmentId) {
      return;
    }

    this.dispatch(R.actions.bookingWizard.setIsLoading(true));
    this.dispatch(R.actions.shipmentDocumentsAll.setQuoteIndex(undefined));

    const roles = await R.services.moduleFunctionalityPermissions.getCurrentShipmentRoles(shipmentId) || [] as EShipmentOrganizationRole[];

    this.dispatch(R.actions.bookingWizard.setShipmentRoles(roles));

    const shipmentShort = await R.services.shipment.getShipmentShortById(+shipmentId);

    if (shipmentShort) {
      this.dispatch(R.actions.bookingWizard.setShipmentData(shipmentShort));

      if (shipmentShort.earliestEmptyReleaseDate) {
        this.dispatch(R.actions.bookingWizard.setEmptyReleaseDate(DateDtm.fromPlain({
          date: shipmentShort.earliestEmptyReleaseDate,
          offset: moment.parseZone(shipmentShort.earliestEmptyReleaseDate).utcOffset(),
        })));
      }
      this.dispatch(R.actions.bookingWizard.setHasTemperatureControl(shipmentShort.hasReeferContainer));
      this.dispatch(R.actions.bookingWizard.setHasSOC(shipmentShort.hasSelfOwnedContainer));
      this.dispatch(R.actions.bookingWizard.setContainersAmount(shipmentShort.containerCount));
    }

    if (shipmentShort?.status === ShipmentStatusEnum.AWAITING_APPROVAL) {
      const shipment = await R.services.shipment.getShipmentById(+shipmentId);

      const { quotaId, quotaRequestId } = shipment;

      const quota = await monetaryR.services.RFQServiceById.getQuotaById(quotaRequestId, quotaId);
      const durationDays = parseDurationFromHoursToDays(quota?.schedules?.[0]?.totalDuration || 0);

      if (quota && quota.cost && quota.incoterm && quota.tradeType) {
        this.dispatch(R.actions.bookingWizard.setTotalCost(this.calculateTotalCost(quota.cost as FreightQuotaContentCostDTM, quota.incoterm as EFreightIncoterms, quota.tradeType as EFreightIncotermsTrade)));
        this.dispatch(R.actions.bookingWizard.setNameSCAC(quota.contracts?.[0].scac || ''));
        this.dispatch(R.actions.bookingWizard.setActiveQuoteCharges(quota?.schedules?.[0]?.charges || []));
      }

      this.dispatch(R.actions.bookingWizard.setDurationDays(durationDays));

      this.dispatch(R.actions.bookingWizard.setShipmentReference(shipmentShort?.reference || undefined));

      if (shipmentShort?.reference) {
        this.dispatch(R.actions.bookingWizard.setShipmentReferenceData(ShipmentReferenceDTM.fromPlain({
          id: undefined,
          references: [
            {
              id: undefined,
              value: shipmentShort?.reference || '',
            },
          ],
        })));
      }
    }

    const containers = await R.services.shipmentContainers.getContainersList(shipmentId);
    this.dispatch(R.actions.bookingWizard.setContainers(containers));

    const shippingParties = await R.services.shippingParties.getList(shipmentId);
    const cargoSupplier = shippingParties.find(({ role }) => role === EShippingPartyTypes.CARGO_SUPPLIER);
    const cargoReceiver = shippingParties.find(({ role }) => role === EShippingPartyTypes.CARGO_RECEIVER);

    this.dispatch(R.actions.bookingWizard.setCargoSupplier(cargoSupplier || null));
    this.dispatch(R.actions.bookingWizard.setCargoReceiver(cargoReceiver || null));

    await this.getCargos(shipmentId, shipmentShort?.status === ShipmentStatusEnum.AWAITING_APPROVAL);

    const cargosInStore = R.selectors.bookingWizard.getCargos(this.store.getState());
    const hasAnyHazmats = cargosInStore.some(({ isHazmat }) => isHazmat);
    this.dispatch(R.actions.bookingWizard.setShouldHaveHazmats(hasAnyHazmats));

    const currentOrganization = userManagementR.selectors.userOrganizationData.getUserOrganization(this.store.getState());
    if (currentOrganization) {
      this.dispatch(R.actions.bookingWizard.setCurrentOrganization(currentOrganization));
    }

    const companiesList = await R.services.contacts.getCompanyList();

    if (cargoSupplier) {
      if (cargoSupplier.company?.id) {
        companiesList.push(cargoSupplier.company);
      }
    }

    if (cargoReceiver) {
      if (cargoReceiver.company?.id) {
        companiesList.push(cargoReceiver.company);
      }
    }

    this.dispatch(R.actions.bookingWizard.setCompaniesList(companiesList));

    let accountStats: ShipmentAccountStatsCustomer | undefined;
    this.dispatch(R.actions.bookingWizard.setIsHaveAccountLimit(false));

    const organization = await userManagementR.services.organization.getCurrentOrganization();

    if (organization?.paymentMethod?.type === OrganizationPaymentMethod.PREPAYMENT) {
      this.dispatch(R.actions.bookingWizard.setIsShowAccountLimit(true));
      this.dispatch(R.actions.bookingWizard.setIsHaveAccountLimit(false));
    }

    if (organization?.paymentMethod?.type === OrganizationPaymentMethod.DEFERRED_PAYMENT) {
      accountStats = await R.services.shipment.getOrganizationAccountStats(PAYABLES);
      const accountBalance = accountStats?.accountBalance < 0 ? (-1 * accountStats?.accountBalance) : accountStats?.accountBalance;

      if (accountBalance && organization?.paymentMethod?.creditLimit && accountBalance > organization?.paymentMethod?.creditLimit) {
        this.dispatch(R.actions.bookingWizard.setIsShowAccountLimit(true));
        this.dispatch(R.actions.bookingWizard.setIsHaveAccountLimit(true));
      }
    }

    const isOriginPartner = R.selectors.bookingWizard.getIsOriginPartner(this.store.getState());

    if (isOriginPartner) {
      const loadingControls = await R.services.loadingControls.getLoadingControls(shipmentId);

      this.dispatch(R.actions.bookingWizard.setSupplierLoadingControls(loadingControls.find(({ drayageSide }) => drayageSide === EDrayageLoadingSide.ORIGIN_DRAYAGE) || null));
      this.dispatch(R.actions.bookingWizard.setReceiverLoadingControls(loadingControls.find(({ drayageSide }) => drayageSide === EDrayageLoadingSide.DESTITATION_DRAYAGE) || null));
    }

    this.dispatch(R.actions.bookingWizard.setIsLoading(false));
  }

  public init = async (shipmentId: string) => {
    this.dispatch(R.actions.bookingWizard.setIsLoading(true));
    this.dispatch(R.actions.shipmentDocumentsAll.setQuoteIndex(undefined));

    if (!shipmentId) {
      return;
    }

    const shipment = await R.services.shipment.getShipmentShortById(+shipmentId);

    if (shipment) {
      this.dispatch(R.actions.bookingWizard.setShipmentData(shipment));
      if (shipment.earliestEmptyReleaseDate) {
        this.dispatch(R.actions.bookingWizard.setEmptyReleaseDate(DateDtm.fromPlain({
          date: shipment.earliestEmptyReleaseDate,
          offset: moment.parseZone(shipment.earliestEmptyReleaseDate).utcOffset(),
        })));
      }
      this.dispatch(R.actions.bookingWizard.setHasTemperatureControl(shipment.hasReeferContainer));
      this.dispatch(R.actions.bookingWizard.setHasSOC(shipment.hasSelfOwnedContainer));
      this.dispatch(R.actions.bookingWizard.setContainersAmount(shipment.containerCount));
    }

    const containers = await R.services.shipmentContainers.getContainersList(shipmentId);
    this.dispatch(R.actions.bookingWizard.setContainers(containers));

    await this.getCargos(shipmentId);
    const cargosInStore = R.selectors.bookingWizard.getCargos(this.store.getState());
    const hasAnyHazmats = cargosInStore.some(({ isHazmat }) => isHazmat);
    this.dispatch(R.actions.bookingWizard.setShouldHaveHazmats(hasAnyHazmats));

    const currentOrganization = userManagementR.selectors.userOrganizationData.getUserOrganization(this.store.getState());
    if (currentOrganization) {
      this.dispatch(R.actions.bookingWizard.setCurrentOrganization(currentOrganization));
    }

    const companiesList = await R.services.contacts.getCompanyList();
    this.dispatch(R.actions.bookingWizard.setCompaniesList(companiesList));

    let accountStats: ShipmentAccountStatsCustomer | undefined;
    this.dispatch(R.actions.bookingWizard.setIsHaveAccountLimit(false));

    const organization = await userManagementR.services.organization.getCurrentOrganization();

    if (organization?.paymentMethod?.type === OrganizationPaymentMethod.PREPAYMENT) {
      this.dispatch(R.actions.bookingWizard.setIsShowAccountLimit(true));
      this.dispatch(R.actions.bookingWizard.setIsHaveAccountLimit(false));
    }

    if (organization?.paymentMethod?.type === OrganizationPaymentMethod.DEFERRED_PAYMENT) {
      accountStats = await R.services.shipment.getOrganizationAccountStats(PAYABLES);
      const accountBalance = accountStats?.accountBalance < 0 ? (-1 * accountStats?.accountBalance) : accountStats?.accountBalance;

      if (accountBalance && organization?.paymentMethod?.creditLimit && accountBalance > organization?.paymentMethod?.creditLimit) {
        this.dispatch(R.actions.bookingWizard.setIsShowAccountLimit(true));
        this.dispatch(R.actions.bookingWizard.setIsHaveAccountLimit(true));
      }
    }

    this.dispatch(R.actions.bookingWizard.setIsLoading(false));
  }

  public setEmptyReleaseDate = (releaseDate: Moment | null) => {
    this.dispatch(R.actions.bookingWizard.setEmptyReleaseDateError(''));

    if (!releaseDate) {
      this.dispatch(R.actions.bookingWizard.setEmptyReleaseDate(null));

      return;
    }

    this.dispatch(R.actions.bookingWizard.setEmptyReleaseDate(DateDtm.fromPlain({
      date: releaseDate?.format(),
      offset: moment.parseZone(releaseDate).utcOffset(),
    })));
  }

  public setHsCode = async (shipmentIdProps: number, hsCode: CargoDTM['code'], cargoId: number) => {
    const shipmentId = R.selectors.bookingWizard.getShipmentId(this.store.getState());

    const { commodities } = R.selectors.commodity.getCommodity(this.store.getState());
    let code = hsCode;
    let name;

    const commodity = commodities.find((item) => item.code === hsCode);
    if (commodity) {
      code = commodity.code;
      name = commodity.name;
    }

    this.dispatch(R.actions.bookingWizard.setHsCodeValidationStatus({
      status: 'REQUEST_NOT_SENT',
      cargoId,
    }));

    this.dispatch(R.actions.bookingWizard.setHsCode({
      code,
      name,
      errorsCode: validateRequiredField(code),
      errorsName: validateRequiredField(name),
      cargoId,
    }));

    const temperatureControl = R.selectors.bookingWizard.getHasTemperatureControl(this.store.getState());

    if (temperatureControl) {
      return;
    }

    if (!code) {
      return;
    }

    try {
      await R.services.hsValidation.getHsGroup(+shipmentId, code);
    } catch (error: unknown) {
      if (error instanceof BadRequestError || error instanceof ConflictError) {
        this.dispatch(R.actions.bookingWizard.setHsCodeError({
          cargoId,
          code: ValidationErrorDTM.fromPlain({
            type: ValidationErrorType.ALERT,
            message: error.getErrorMessage(),
          }),
        }));
      }

      this.dispatch(R.actions.bookingWizard.setHsCodeValidationStatus({
        status: 'REQUEST_SENT_AND_ERROR',
        cargoId,
      }));

      throw error;
    }

    this.dispatch(R.actions.bookingWizard.setHsCodeValidationStatus({
      status: 'REQUEST_SENT_AND_VALID',
      cargoId,
    }));
  };

  public touchCargoField = (fieldName: string, cargoId: number) => {
    this.dispatch(R.actions.bookingWizard.touchCargoField({
      field: fieldName,
      cargoId,
    }));
  }

  public setDescription = (description: string, cargoId: number) => {
    this.dispatch(R.actions.bookingWizard.setDescription({
      description: description?.slice(0, 512),
      cargoId,
    }));
  };

  public setPackageType = (packageType: CargoDTM['packageType'], cargoId: number) => {
    this.dispatch(R.actions.bookingWizard.setPackageType({
      packageType,
      cargoId,
      error: validateRequiredField(packageType),
    }));
  };

  public setPackagesNumber = (packagesNumber: CargoDTM['packagesNumber'], cargoId: number) => {
    const slicedPackagesNumber = packagesNumber ? (
      packagesNumber
        .replace(/^0+/, '')
        .replace(/[^0-9]/g, '')
        .slice(0, 6)
    ) : undefined;

    this.dispatch(R.actions.bookingWizard.setPackagesNumber({
      packagesNumber: slicedPackagesNumber,
      error: validateRequiredField(slicedPackagesNumber),
      cargoId,
    }));
  };

  public setWeight = (weight: CargoDTM['weight'], cargoId: number) => {
    const slicedWeight = weight ? validateDecimalFraction(weight, 11, 3, {
      withoutZero: true,
      integerMaxLength: 7,
    }) : undefined;

    this.dispatch(R.actions.bookingWizard.setWeight({
      weight: slicedWeight,
      error: validateRequiredField(weight),
      cargoId,
    }));
  };

  public setVolume = (volume: CargoDTM['volume'], cargoId: number) => {
    const slicedVolume = volume ? validateDecimalFraction(volume, 8, 3, {
      withoutZero: true,
      integerMaxLength: 4,
    }) : undefined;

    this.dispatch(R.actions.bookingWizard.setVolume({
      volume: slicedVolume || '',
      cargoId,
    }));
  };

  public setValue = (value: CargoDTM['value'], cargoId: number) => {
    this.dispatch(R.actions.bookingWizard.setValue({
      value: value || '',
      error: validateRequiredField(value),
      cargoId,
    }));
  };

  public removeCargo = (cargoId: number) => {
    this.dispatch(R.actions.bookingWizard.removeCargoById(cargoId));
    this.dispatch(R.actions.bookingWizard.addCargosToRemoveId(String(cargoId)));
  }

  public addNewCargo = () => {
    const cargos = R.selectors.bookingWizard.getCargos(this.store.getState());
    const [initialCargo] = cargos;

    const hasTemperatureControl = R.selectors.bookingWizard.getHasTemperatureControl(this.store.getState());

    if (hasTemperatureControl && initialCargo && initialCargo.code) {
      this.dispatch(R.actions.bookingWizard.addCargo(initialCargo.code));
      return;
    }

    this.dispatch(R.actions.bookingWizard.addCargo());
  }

  public openHazmatSectionByCargoId = (cargoId: number) => {
    this.dispatch(R.actions.bookingWizard.addToggledHazmatCargoId(cargoId));
  }

  public closeHazmatSectionByCargoId = (cargoId: number) => {
    this.dispatch(R.actions.bookingWizard.removeToggledHazmatCargoId(cargoId));
  }

  public setChosenContactId = (contactId: number) => {
    this.dispatch(R.actions.bookingWizard.setChosenContactId(contactId));
  }

  public setImoClass = (imoClass: CargoDTM['imoClass'], cargoId: number) => {
    this.dispatch(R.actions.bookingWizard.setImoClass({
      imoClass,
      cargoId,
    }));

    const isCargoForcedToBeHazmat = R.selectors.bookingWizard.getIsCargoForcedToBeHazmat(this.store.getState());

    this.updateHazmatStatus(cargoId, isCargoForcedToBeHazmat);
    this.updateHazmatErrors(cargoId);
    this.checkCargoHazmatEmptynessCriteria();
  }

  public setUnNumber = (unNumber: CargoDTM['unNumber'], cargoId: number) => {
    const slicedUnNumber = unNumber?.replace(/\D/g, '').slice(0, 4);
    this.dispatch(R.actions.bookingWizard.setUnNumber({
      unNumber: slicedUnNumber,
      cargoId,
    }));

    const isCargoForcedToBeHazmat = R.selectors.bookingWizard.getIsCargoForcedToBeHazmat(this.store.getState());

    this.updateHazmatStatus(cargoId, isCargoForcedToBeHazmat);
    this.updateHazmatErrors(cargoId);
    this.checkCargoHazmatEmptynessCriteria();
  }

  public selectContactFromBook = () => {
    const activeTab = R.selectors.bookingWizard.getSupplierStepActiveTab(this.store.getState());
    const contacts = R.selectors.bookingWizard.getContactsForChosenCompany(this.store.getState());
    const contactId = R.selectors.bookingWizard.getChosenContactId(this.store.getState());
    const targetContact = contacts.find((contact) => contact.id === contactId);

    if (!targetContact) {
      appUC.drawer.closeDrawer();

      return;
    }

    const cargoSupplier = R.selectors.bookingWizard.getCargoSupplier(this.store.getState());
    const cargoReceiver = R.selectors.bookingWizard.getCargoReceiver(this.store.getState());

    if (activeTab === EPickupDeliveryTabs.CARGO_SUPPLIER && cargoSupplier) {
      this.dispatch(R.actions.bookingWizard.setCargoSupplier(ShippingPartyDTM.fromPlain({
        ...cargoSupplier,
        contact: {
          ...targetContact,
        },
      })));
    } else if (activeTab === EPickupDeliveryTabs.CARGO_RECEIVER && cargoReceiver) {
      this.dispatch(R.actions.bookingWizard.setCargoReceiver(ShippingPartyDTM.fromPlain({
        ...cargoReceiver,
        contact: {
          ...targetContact,
        },
      })));
    }

    appUC.drawer.closeDrawer();
  };

  public saveAddressToContactBook = async () => {
    const suppliersStepActiveTab = R.selectors.bookingWizard.getSupplierStepActiveTab(this.store.getState());
    const cargoSupplier = R.selectors.bookingWizard.getCargoSupplier(this.store.getState());
    const cargoReceiver = R.selectors.bookingWizard.getCargoReceiver(this.store.getState());

    const shippingParty = suppliersStepActiveTab === EPickupDeliveryTabs.CARGO_SUPPLIER ? cargoSupplier : cargoReceiver;

    if (!shippingParty || !shippingParty.company || !shippingParty.address) {
      return;
    }

    this.dispatch(R.actions.bookingWizard.setIsSavingAddress(true));

    const { company, address } = shippingParty;

    const savedAddress = await userManagementR.services.contacts.postAddress(company.id, {
      id: address.id || undefined,
      country: address.country || null,
      state: address.state || null,
      city: address.city || null,
      address1: address.address1 || null,
      address2: address.address2 || null,
      postalCode: address.postalCode || null,
      closestPort: 'USNYC',
      primary: false,
      company: null,
    });

    const companiesList = [...R.selectors.bookingWizard.getCompaniesList(this.store.getState())];
    const targetCompanyIndex = companiesList.findIndex((_company) => _company.id === company.id);
    const targetCompany = companiesList[targetCompanyIndex];

    if (!targetCompany) {
      this.dispatch(R.actions.bookingWizard.setIsSavingAddress(false));

      return;
    }

    const newTargetCompany = CompanyDTM.fromPlain({
      ...targetCompany,
      addresses: [
        ...targetCompany.addresses,
        savedAddress,
      ],
    });

    companiesList.splice(targetCompanyIndex, 1, newTargetCompany);

    notification.success({
      message: i18n.t('Successfuly saved address to contact book'),
      placement: 'topRight',
      duration: 5,
    });

    this.dispatch(R.actions.bookingWizard.setCompaniesList(companiesList));
    this.dispatch(R.actions.bookingWizard.setIsSavingAddress(false));
  };

  public setPackingGroup = (packingGroup: CargoDTM['packingGroup'], cargoId: number) => {
    this.dispatch(R.actions.bookingWizard.setPackingGroup({
      packingGroup,
      cargoId,
    }));

    const isCargoForcedToBeHazmat = R.selectors.bookingWizard.getIsCargoForcedToBeHazmat(this.store.getState());

    this.updateHazmatStatus(cargoId, isCargoForcedToBeHazmat);
    this.updateHazmatErrors(cargoId);
    this.checkCargoHazmatEmptynessCriteria();
  }

  public setShippingName = (shippingName: CargoDTM['shippingName'], cargoId: number) => {
    const slicedShippingName = shippingName?.slice(0, 90);
    this.dispatch(R.actions.bookingWizard.setShippingName({
      shippingName: slicedShippingName,
      cargoId,
    }));

    const isCargoForcedToBeHazmat = R.selectors.bookingWizard.getIsCargoForcedToBeHazmat(this.store.getState());

    this.updateHazmatStatus(cargoId, isCargoForcedToBeHazmat);
    this.updateHazmatErrors(cargoId);
    this.checkCargoHazmatEmptynessCriteria();
  }

  public setShipperCompany = (companyId: number) => {
    const companiesList = R.selectors.bookingWizard.getCompaniesList(this.store.getState());
    const company = companiesList.find(({ id }) => id === companyId);

    this.dispatch(R.actions.bookingWizard.setSelectedCompany(company || null));
    this.dispatch(R.actions.bookingWizard.setCompanyError(false));
  }

  public setConsigneeCompany = (companyId: number) => {
    const companiesList = R.selectors.bookingWizard.getCompaniesList(this.store.getState());
    const company = companiesList.find(({ id }) => id === companyId);

    this.dispatch(R.actions.bookingWizard.setSelectedConsigneeCompany(company || null));
    this.dispatch(R.actions.bookingWizard.setConsigneeCompanyError(false));
  }

  public onChangeShippingPartyReference = (value: string) => {
    const validValue = value
      .replace(/[^\w]/g, '')
      .slice(0, 16);

    this.dispatch(R.actions.bookingWizard.setShipmentPartyReference(validValue));

    this.onValidateShippingPartyReference();
  }

  public onValidateShippingPartyReference = () => {
    const reference = R.selectors.bookingWizard.getShipmentReference(this.store.getState());

    if (reference) {
      this.dispatch(R.actions.bookingWizard.setShipmentPartyReferenceError(false));
    } else {
      this.dispatch(R.actions.bookingWizard.setShipmentPartyReferenceError(true));
    }
  }

  public onChangeConsigneeReference = (value: string) => {
    const validValue = value
      .replace(/[^\w]/g, '')
      .slice(0, 16);

    this.dispatch(R.actions.bookingWizard.setShipmentConsigneeReference(validValue));

    this.onValidateConsigneeReference();
  }

  public onValidateConsigneeReference = () => {
    const reference = R.selectors.bookingWizard.getShipmentConsigneeReference(this.store.getState());

    if (reference) {
      this.dispatch(R.actions.bookingWizard.setShipmentConsigneeReferenceError(false));
    } else {
      this.dispatch(R.actions.bookingWizard.setShipmentConsigneeReferenceError(true));
    }
  }

  public onChangeReference = (value: string) => {
    const validValue = value
      .replace(/[^\w]/g, '')
      .slice(0, 16);

    this.dispatch(R.actions.bookingWizard.setShipmentReference(validValue));

    this.onValidateReference();
  }

  public onValidateReference = () => {
    const reference = R.selectors.bookingWizard.getShipmentReference(this.store.getState());

    if (reference) {
      this.dispatch(R.actions.bookingWizard.setShipmentReferenceError(false));
    } else {
      this.dispatch(R.actions.bookingWizard.setShipmentReferenceError(true));
    }
  }

  public setSupplierLoadingMethod = (value: string) => {
    const loadingMethod = value as EShipmentLoadingMethod;
    let loadingControls = R.selectors.bookingWizard.getSupplierLoadingControls(this.store.getState());

    if (!loadingControls) {
      loadingControls = LoadingControlDTM.createDefault(EDrayageLoadingSide.ORIGIN_DRAYAGE);
    }

    if (loadingMethod === EShipmentLoadingMethod.DROP_AND_PICKUP) {
      loadingControls.receivingMethod = undefined;
    }

    this.dispatch(R.actions.bookingWizard.setSupplierLoadingControls(LoadingControlDTM.fromPlain({
      ...loadingControls,
      loadingMethod,
    })));
  }

  public setSupplierReceivingMethod = (receivingMethod: string) => {
    let loadingControls = R.selectors.bookingWizard.getSupplierLoadingControls(this.store.getState());

    if (!loadingControls) {
      loadingControls = LoadingControlDTM.createDefault(EDrayageLoadingSide.ORIGIN_DRAYAGE);
    }

    this.dispatch(R.actions.bookingWizard.setSupplierLoadingControls(LoadingControlDTM.fromPlain({
      ...loadingControls,
      receivingMethod: receivingMethod as EShipmentReceivingMethod,
    })));
  }

  public setReceiverLoadingMethod = (value: string) => {
    const loadingMethod = value as EShipmentLoadingMethod;
    let loadingControls = R.selectors.bookingWizard.getReceiverLoadingControls(this.store.getState());

    if (!loadingControls) {
      loadingControls = LoadingControlDTM.createDefault(EDrayageLoadingSide.DESTITATION_DRAYAGE);
    }

    if (loadingMethod === EShipmentLoadingMethod.DROP_AND_PICKUP) {
      loadingControls.loadingMethod = undefined;
    }

    this.dispatch(R.actions.bookingWizard.setReceiverLoadingControls(LoadingControlDTM.fromPlain({
      ...loadingControls,
      loadingMethod,
    })));
  }

  public setReceiverReceivingMethod = (receivingMethod: string) => {
    let loadingControls = R.selectors.bookingWizard.getReceiverLoadingControls(this.store.getState());

    if (!loadingControls) {
      loadingControls = LoadingControlDTM.createDefault(EDrayageLoadingSide.DESTITATION_DRAYAGE);
    }

    this.dispatch(R.actions.bookingWizard.setReceiverLoadingControls(LoadingControlDTM.fromPlain({
      ...loadingControls,
      receivingMethod: receivingMethod as EShipmentReceivingMethod,
    })));
  }

  public downloadContainerDocument = (containerId: string) => {
    const shipmentId = R.selectors.bookingWizard.getShipmentId(this.store.getState());
    const { seaworthyCertificate } = R.selectors.bookingWizard.getContainerById(containerId)(this.store.getState());

    if (!seaworthyCertificate) {
      return;
    }

    R.services.shipmentDocument.getShipmentDocument(+shipmentId, seaworthyCertificate.response.id, seaworthyCertificate.response.name);
  }

  public downloadMsdsDocument = (shipmentIdProps: number, cargoId: number) => {
    const shipmentId = R.selectors.bookingWizard.getShipmentId(this.store.getState());
    const cargos = R.selectors.bookingWizard.getCargos(this.store.getState());
    const cargo = cargos.find(({ id }) => id === cargoId);

    if (!cargo) {
      return;
    }

    const { msdsDocument } = cargo;

    if (msdsDocument[0].status !== 'done') {
      return;
    }

    const documentId = msdsDocument[0].response.id;
    const documentName = msdsDocument[0].response.name;

    R.services.shipmentDocument.getShipmentDocument(+shipmentId, documentId, documentName);
  };

  public setMsdsDocument = (msdsDocument: CargoDTM['msdsDocument'], cargoId: number) => {
    this.dispatch(R.actions.bookingWizard.setMsdsDocument({
      msdsDocument,
      cargoId,
    }));

    const isCargoForcedToBeHazmat = R.selectors.bookingWizard.getIsCargoForcedToBeHazmat(this.store.getState());

    this.updateHazmatStatus(cargoId, isCargoForcedToBeHazmat);
    this.updateHazmatErrors(cargoId);
    this.checkCargoHazmatEmptynessCriteria();
  }

  public setContainerDocument = (document: ContainerDocumentDTM | null, containerId: string) => {
    this.dispatch(R.actions.bookingWizard.setContainerDocument({
      document,
      containerId,
    }));

    this.validateContainer(containerId);
  }

  public goPickupAndDeliveryWithContainerValueCheck = async () => {
    this.validateCargosDrayageRequiredFields();
    this.validateCargosHazmat();
    this.validateShipperOrConsigneeInformation();
    this.validateContainers();

    const hasTemperatureControl = R.selectors.bookingWizard.getHasTemperatureControl(this.store.getState());

    if (hasTemperatureControl) {
      UC.temperatureControl.updateTemperatureErrors();
      this.dispatch(R.actions.temperatureControl.setUpdateAttemptStatus(true));
    }

    const hasErrorsAtAnyCargo = R.selectors.bookingWizard.hasErrorsAtAnyCargo(this.store.getState());
    const hasEmptyReleaseDateError = !!R.selectors.bookingWizard.getEmptyReleaseDateError(this.store.getState());
    const isHazmatErrorVisible = R.selectors.bookingWizard.getIsHazmatErrorVisible(this.store.getState());
    const hasErrorsAtAnyContainers = R.selectors.bookingWizard.hasErrorsAtAnyContainers(this.store.getState());
    const temperatureControlErrors = R.selectors.temperatureControl.getTemperatureControlErrors(this.store.getState());
    const shipperError = R.selectors.bookingWizard.getCompanyError(this.store.getState());
    const hasTemperatureErrors = temperatureControlErrors.temperature || temperatureControlErrors.flowRate || temperatureControlErrors.flowRateUnit;

    if (!hasErrorsAtAnyCargo
      && !hasEmptyReleaseDateError
      && !shipperError
      && !isHazmatErrorVisible
      && (!hasTemperatureControl || (hasTemperatureControl && !hasTemperatureErrors))
      && !hasErrorsAtAnyContainers
    ) {
      const isContainersValueCostExceeded = this.validateIfContainersValueCostIsExceeded();

      if (isContainersValueCostExceeded) {
        Modal.confirm({
          title: i18n.t('Exceeded Limit of Commodity Value'),
          content: i18n.t('Please note that since the total value of the goods in the container exceeds $300,000, your request will be evaluated by our booking team for further review'),
          okText: i18n.t('Confirm'),
          cancelText: i18n.t('Cancel'),
          onOk: this.goPickupAndDelivery,
        });

        return;
      }

      await this.goPickupAndDelivery();
    }
  };

  public goPickupAndDelivery = async () => {
    this.dispatch(R.actions.bookingWizard.setIsLoading(true));
    this.dispatch(R.actions.bookingWizard.setIsContentUpdating(true));

    const shipmentId = R.selectors.bookingWizard.getShipmentId(this.store.getState());
    const temperatureControl = R.selectors.temperatureControl.getTemperatureControl(this.store.getState());
    const hasTemperatureControl = R.selectors.bookingWizard.getHasTemperatureControl(this.store.getState());
    const emptyReleaseDate = R.selectors.bookingWizard.getEmptyReleaseDate(this.store.getState());
    const shouldShowShipperInformation = R.selectors.bookingWizard.getShouldShowShipperSection(this.store.getState());
    const shouldShowConsigneeInformation = R.selectors.bookingWizard.getShouldShowConsigneeSection(this.store.getState());
    const reference = R.selectors.bookingWizard.getShipmentReference(this.store.getState());

    this.updateAdditionalServices();

    await this.updateAllCargos(+shipmentId);

    await this.updateAllContainers(shipmentId);

    if (hasTemperatureControl) {
      await this.updateTemperatureControl(temperatureControl);
    }

    if (reference) {
      await this.saveShipmentReference();
    }

    if (shouldShowShipperInformation || shouldShowConsigneeInformation) {
      await this.saveShipperOrConsigneeCompanyInformation();
    }

    if (emptyReleaseDate) {
      await R.services.shipment.updateEmptyReleaseDate(shipmentId, emptyReleaseDate.format());
    }

    this.dispatch(R.actions.bookingWizard.setStep(2));

    this.dispatch(R.actions.bookingWizard.setIsLoading(false));
    this.dispatch(R.actions.bookingWizard.setIsContentUpdating(false));
  };

  public goSecondStep = async () => {
    this.dispatch(R.actions.bookingWizard.setIsLoading(true));
    this.dispatch(R.actions.bookingWizard.setIsContentUpdating(true));
    const shipmentId = R.selectors.bookingWizard.getShipmentId(this.store.getState());
    const temperatureControl = R.selectors.temperatureControl.getTemperatureControl(this.store.getState());
    const hasTemperatureControl = R.selectors.bookingWizard.getHasTemperatureControl(this.store.getState());
    const emptyReleaseDate = R.selectors.bookingWizard.getEmptyReleaseDate(this.store.getState());
    const shouldShowShipperInformation = R.selectors.bookingWizard.getShouldShowShipperSection(this.store.getState());
    const shouldShowConsigneeInformation = R.selectors.bookingWizard.getShouldShowConsigneeSection(this.store.getState());
    const reference = R.selectors.bookingWizard.getShipmentReference(this.store.getState());

    this.validateCargosRequiredFields();
    this.validateCargosHazmat();
    this.validateShipperOrConsigneeInformation();
    this.validateContainers();

    if (hasTemperatureControl) {
      UC.temperatureControl.updateTemperatureErrors();
      this.dispatch(R.actions.temperatureControl.setUpdateAttemptStatus(true));
    }

    const hasErrorsAtAnyCargo = R.selectors.bookingWizard.hasErrorsAtAnyCargo(this.store.getState());
    const hasEmptyReleaseDateError = !!R.selectors.bookingWizard.getEmptyReleaseDateError(this.store.getState());
    const isHazmatErrorVisible = R.selectors.bookingWizard.getIsHazmatErrorVisible(this.store.getState());
    const hasErrorsAtAnyContainers = R.selectors.bookingWizard.hasErrorsAtAnyContainers(this.store.getState());
    const temperatureControlErrors = R.selectors.temperatureControl.getTemperatureControlErrors(this.store.getState());
    const shipperError = R.selectors.bookingWizard.getCompanyError(this.store.getState());
    const hasTemperatureErrors = temperatureControlErrors.temperature || temperatureControlErrors.flowRate || temperatureControlErrors.flowRateUnit;

    if (!hasErrorsAtAnyCargo
      && !hasEmptyReleaseDateError
      && !shipperError
      && !isHazmatErrorVisible
      && (!hasTemperatureControl || (hasTemperatureControl && !hasTemperatureErrors))
      && !hasErrorsAtAnyContainers
    ) {
      this.updateAdditionalServices();

      await this.updateAllCargos(+shipmentId);

      await this.updateAllContainers(shipmentId);

      if (hasTemperatureControl) {
        await this.updateTemperatureControl(temperatureControl);
      }

      if (reference) {
        await this.saveShipmentReference();
      }

      if (shouldShowShipperInformation || shouldShowConsigneeInformation) {
        await this.saveShipperOrConsigneeCompanyInformation();
      }

      if (emptyReleaseDate) {
        try {
          await R.services.shipment.updateEmptyReleaseDate(shipmentId, emptyReleaseDate.format());
        } catch (e) {
          const error = e as Error;

          this.dispatch(R.actions.bookingWizard.setEmptyReleaseDateError(error.message));
          this.dispatch(R.actions.bookingWizard.setWasInformationSavedOnce(true));
          this.dispatch(R.actions.bookingWizard.setIsContentUpdating(false));
          this.dispatch(R.actions.bookingWizard.setIsLoading(false));
          return;
        }
      }

      const isCustomerOrg = R.selectors.bookingWizard.getIsCurrentOrganizationCustomer(this.store.getState());

      if (isCustomerOrg) {
        this.dispatch(R.actions.bookingWizard.setStep(2));
        this.dispatch(R.actions.bookingWizard.setWasInformationSavedOnce(true));
      } else {
        await this.agreeAndBook();
      }
    }

    this.dispatch(R.actions.bookingWizard.setIsLoading(false));
    this.dispatch(R.actions.bookingWizard.setIsContentUpdating(false));
  }

  public validateShipperOrConsigneeInformation = () => {
    const shouldShowShipperSection = R.selectors.bookingWizard.getShouldShowShipperSection(this.store.getState());
    const shouldShowConsigneeSection = R.selectors.bookingWizard.getShouldShowConsigneeSection(this.store.getState());
    const shipperOrConsigneeCompany = R.selectors.bookingWizard.getSelectedCompany(this.store.getState());

    if ((shouldShowConsigneeSection || shouldShowShipperSection) && !shipperOrConsigneeCompany) {
      this.dispatch(R.actions.bookingWizard.setCompanyError(true));
    }
  };

  public saveShipperOrConsigneeCompanyInformation = async () => {
    const shipperCompany = R.selectors.bookingWizard.getSelectedCompany(this.store.getState());
    const selectedConsigneeCompany = R.selectors.bookingWizard.getSelectedConsigneeCompany(this.store.getState());
    const shipmentPartyReference = R.selectors.bookingWizard.getShipmentPartyReference(this.store.getState());
    const shipmentConsigneeReference = R.selectors.bookingWizard.getShipmentConsigneeReference(this.store.getState());

    if (!shipperCompany) {
      return;
    }

    let fullCompaniesList: FullCompanyDTM[] = [];
    let newShippingParty: ShippingPartyDTM | null = null;
    let newShippingConsigneeParty: ShippingPartyDTM | null = null;

    fullCompaniesList = await R.services.contacts.getFullCompanyList();

    if (fullCompaniesList) {
      if (shipperCompany) {
        newShippingParty = await this.saveShippingParty(shipperCompany.id, EShippingPartyTypes.HOUSE_SHIPPER, shipmentPartyReference, fullCompaniesList);
      }

      if (selectedConsigneeCompany) {
        newShippingConsigneeParty = await this.saveShippingParty(selectedConsigneeCompany.id, EShippingPartyTypes.HOUSE_CONSIGNEE, shipmentConsigneeReference, fullCompaniesList);
      }

      if (newShippingParty?.id) {
        this.dispatch(R.actions.bookingWizard.setNewBookPartiesId(newShippingParty.id));
      }
      if (newShippingConsigneeParty?.id) {
        this.dispatch(R.actions.bookingWizard.setNewBookPartiesConsigneeId(newShippingConsigneeParty.id));
      }
    }
  };

  private saveShippingParty = async (shipperCompanyId: number, role: EShippingPartyTypes, shipmentPartyReference: string | undefined, fullCompaniesList: FullCompanyDTM[]) => {
    const fullCompany = fullCompaniesList.find((company) => company.id === shipperCompanyId);

    let newShippingParty: ShippingPartyDTM | null = null;
    let addressId = 0;
    let contactId = 0;

    if (!fullCompany) {
      return null;
    }

    const { addresses } = fullCompany;
    const primaryAddress = addresses.find(({ primary }) => primary);

    if (!primaryAddress) {
      return null;
    }

    const { contacts } = primaryAddress;
    const primaryContacts = contacts.find(({ primary }) => primary);

    addressId = primaryAddress ? primaryAddress.id : 0;
    contactId = primaryContacts ? primaryContacts.id : 0;

    const shipmentId = R.selectors.bookingWizard.getShipmentId(this.store.getState());
    const newBookPartiesId = R.selectors.bookingWizard.getNewBookPartiesId(this.store.getState());
    const newBookPartiesConsigneeId = R.selectors.bookingWizard.getNewBookPartiesConsigneeId(this.store.getState());

    const references = shipmentPartyReference
      ? [ShippingPartyReference.fromPlain({ id: uuidv4(), value: shipmentPartyReference })]
      : [];

    const address = await R.services.contacts.getAddress(shipperCompanyId, addressId);
    const contact = address.contacts?.find(({ primary }) => primary);

    const shippingPartyToSave = ShippingPartyDTM.fromPlain({
      id: role === EShippingPartyTypes.HOUSE_CONSIGNEE ? newBookPartiesConsigneeId : newBookPartiesId,
      role,
      company: {
        name: fullCompany.name,
        id: shipperCompanyId,
      },
      address: {
        country: address.country,
        city: address.city,
        address1: address.address1,
        postalCode: address.postalCode,
        closestPort: address.closestPort,
        id: addressId,
      },
      contact: {
        id: contactId,
        fullName: contact?.fullName || '',
        email: contact?.email || '',
        phone: contact?.phone || '',
      },
      addressList: [],
      contactList: [],
      references,
      description: '',
    });

    if (newBookPartiesId) {
      newShippingParty = await R.services.shippingParties.putShippingParty(+shipmentId, shippingPartyToSave);
    } else {
      newShippingParty = await R.services.shippingParties.postShippingParty(+shipmentId, shippingPartyToSave);
    }

    return newShippingParty;
  }

  public async agreeAndBook() {
    const shipmentId = R.selectors.bookingWizard.getShipmentId(this.store.getState());
    const isCustomerOrg = R.selectors.bookingWizard.getIsCurrentOrganizationCustomer(this.store.getState());
    const shipment = R.selectors.bookingWizard.getShipment(this.store.getState());

    this.dispatch(R.actions.bookingWizard.setIsContentUpdating(true));
    this.dispatch(R.actions.bookingWizard.setIsLoading(true));

    if (isCustomerOrg) {
      if (shipment?.isUsShipmentOriginOrDestination()) {
        await R.services.shipment.postConfirmations(shipmentId, ShipmentConfirmationDTM.fromPlain({
          type: EShipmentConfirmationTypes.NEGOTIATED_RATE_ARRANGEMENT,
        }));
      }

      await R.services.shipment.postConfirmations(shipmentId, ShipmentConfirmationDTM.fromPlain({
        type: EShipmentConfirmationTypes.TERMS_AND_CONDITIONS,
      }));
    }

    let isBookingSuccessful = false;

    try {
      isBookingSuccessful = await R.services.inttra.submitBooking(shipmentId);
    } catch (e) {
      this.dispatch(R.actions.bookingWizard.setIsContentUpdating(false));
      this.dispatch(R.actions.bookingWizard.setIsLoading(false));

      throw e;
    }

    if (isBookingSuccessful && shipment?.status === ShipmentStatusEnum.AWAITING_APPROVAL) {
      const updatedShipment = await R.services.shipment.getShipmentShortById(+shipmentId);

      this.dispatch(R.actions.shipment.setShipment(ShipmentPreviewDTM.fromPlain(updatedShipment)));
    }

    this.closeWizard();
    this.navigate(RouteNames.SHIPMENT_OVERVIEW(shipmentId));
    this.dispatch(R.actions.bookingWizard.setIsContentUpdating(false));
    this.dispatch(R.actions.bookingWizard.setIsLoading(false));

    if (isBookingSuccessful) {
      this.dispatch(R.actions.overview.setBookingRequestSuccessful(true));
      return;
    }

    this.dispatch(R.actions.overview.setBookingRequestSuccessful(false));
  }

  public updateTemperatureControl = async (temperatureControl: TemperatureControlDTM) => {
    const shipmentId = R.selectors.bookingWizard.getShipmentId(this.store.getState());
    const temperatureControlRes = await R.services.temperatureControl.putTemperatureControlData(shipmentId, temperatureControl);

    if (temperatureControlRes) {
      this.dispatch(R.actions.temperatureControl.setTemperatureControlData(temperatureControlRes));
    }
  }

  public updateAdditionalServices = async () => {
    const shipmentId = R.selectors.bookingWizard.getShipmentId(this.store.getState());
    const additionalServicesDrawerChecked = this.mobxStore?.additionalServicesDrawerStore?.getAddAdditionalServicesBookingDrawerChecked;

    if (additionalServicesDrawerChecked) {
      const additionalServices: AdditionalServiceCheckedDTM[] = [];

      additionalServicesDrawerChecked.forEach((item) => {
        item.chargeCode.phases?.forEach((itemPhase) => {
          if (itemPhase === item.designation) {
            const newService = AdditionalServiceCheckedDTM.fromPlain({
              code: item.chargeCode.code,
              phase: itemPhase,
              quantity: item.countDocument ? Number(item.countDocument) : 1,
            });

            additionalServices.push(newService);
          }
        });
      });

      await R.services.shipmentAdditionalServices.postShipmentByIdAdditionalServices(shipmentId, additionalServices);
    }
  }

  public saveShipmentReference = async () => {
    const shipmentId = R.selectors.bookingWizard.getShipmentId(this.store.getState());
    const reference = R.selectors.bookingWizard.getShipmentReference(this.store.getState());
    const referenceData = R.selectors.bookingWizard.getShipmentReferenceData(this.store.getState());

    let responseReference: ShipmentReferenceDTM | undefined = referenceData;

    if (!reference) {
      return;
    }

    if (!responseReference) {
      responseReference = await R.services.shipmentDocument.postAdditionalDetails(+shipmentId, {
        id: null,
        references: [{
          id: null,
          value: reference,
        }],
      });
    } else {
      responseReference = await R.services.shipmentDocument.putAdditionalDetails(+shipmentId, {
        id: responseReference?.id || null,
        references: [{
          id: responseReference?.references?.[0]?.id || null,
          value: reference,
        }],
      });
    }

    this.dispatch(R.actions.bookingWizard.setShipmentReferenceData(responseReference));
  }

  public goFirstStep = () => {
    this.dispatch(R.actions.bookingWizard.setStep(1));
  }

  public setSecondStep = () => {
    this.dispatch(R.actions.bookingWizard.setStep(2));
  }

  public closeWizard = () => {
    appUC.drawer.closeDrawer();
    this.dispatch(R.actions.bookingWizard.reset());
    this.dispatch(R.actions.temperatureControl.clear());

    new AdditionalDrawersUseCase(this).resetBySubmitStateAddAdditionalServicesBookingDrawer();
  }

  public updateAllContainers = async (shipmentId: string) => {
    const containers = R.selectors.bookingWizard.getContainers(this.store.getState());

    await Promise.all(containers.map((container) => R.services.shipmentContainers.putContainer(shipmentId, container)));
  }

  public updateAllCargos = async (shipmentId: number) => {
    const cargos = [...R.selectors.bookingWizard.getCargos(this.store.getState())];
    const cargosToDeleteIds = R.selectors.bookingWizard.getCargosToDeleteIds(this.store.getState());
    const createdCargosIds = R.selectors.bookingWizard.getCreatedCargosIds(this.store.getState());

    const cargosToUpdateOrCreate = cargos.filter(({ id }) => !cargosToDeleteIds.includes(String(id)));
    const cargosToDelete = cargosToDeleteIds.filter((id) => cargosToDeleteIds.includes(id) && !createdCargosIds.includes(id));

    await Promise.all(cargosToUpdateOrCreate.map((cargo) => this.updateCargo(shipmentId, cargo)));
    await Promise.all(cargosToDelete.map((cargoId) => this.deleteCargo(shipmentId, Number(cargoId))));

    this.dispatch(R.actions.bookingWizard.clearCreatedCargosIds());
    this.dispatch(R.actions.bookingWizard.clearCargosToRemoveId());

    await this.getCargos(String(shipmentId));
  };

  public deleteCargo = async (shipmenId: number, cargoId: number) => {
    await R.services.cargo.deleteCargo(shipmenId, cargoId);
  };

  public updateCargo = async (shipmenId: number, cargo: CargoDTM) => {
    const createdCargosIds = R.selectors.bookingWizard.getCreatedCargosIds(this.store.getState());
    const isNewCargo = createdCargosIds.includes(String(cargo?.id));

    if (cargo.errors.hasErrors()) {
      return null;
    }

    let response = null;

    if (!isNewCargo) {
      response = await R.services.cargo.putCargo(shipmenId, cargo);
    }
    if (isNewCargo) {
      response = await R.services.cargo.postCargo(shipmenId, cargo);
    }

    return response;
  };

  public setIsTermsAndConditionsChecked = (value: boolean) => {
    this.dispatch(R.actions.bookingWizard.setIsTermsAndConditionsChecked(value));
  };

  public setIsNRAChecked = (value: boolean) => {
    this.dispatch(R.actions.bookingWizard.setIsNRAChecked(value));
  };

  public setContainerNumber = (containerId: string, containerNumber: string) => {
    this.dispatch(R.actions.bookingWizard.setContainerNumber({
      id: containerId,
      containerNumber,
    }));

    this.validateContainer(containerId);
  };

  public setSealNumber = (containerId: string, sealNumber: string) => {
    this.dispatch(R.actions.bookingWizard.setSealNumber({
      id: containerId,
      sealNumber,
    }));
  };

  public setEmptyReleaseDateError = (error: string) => {
    this.dispatch(R.actions.bookingWizard.setEmptyReleaseDateError(error));
  }

  public setSupplierCompanyId = (id: number) => {
    const cargoSupplier = R.selectors.bookingWizard.getCargoSupplier(this.store.getState());
    const companiesList = R.selectors.bookingWizard.getCompaniesList(this.store.getState());
    const targetCompany = companiesList.find((company) => company.id === id);

    if (cargoSupplier && targetCompany) {
      this.dispatch(R.actions.bookingWizard.setCargoSupplier(ShippingPartyDTM.fromPlain({
        ...cargoSupplier,
        company: {
          ...targetCompany,
          id,
        },
      })));
    }
  }

  public setReceiverCompanyId = (id: number) => {
    const cargoReceiver = R.selectors.bookingWizard.getCargoReceiver(this.store.getState());
    const companiesList = R.selectors.bookingWizard.getCompaniesList(this.store.getState());
    const targetCompany = companiesList.find((company) => company.id === id);

    if (cargoReceiver && targetCompany) {
      this.dispatch(R.actions.bookingWizard.setCargoReceiver(ShippingPartyDTM.fromPlain({
        ...cargoReceiver,
        company: {
          ...targetCompany,
          id,
        },
      })));
    }
  }

  public setCargoSupplierAddressField = (field: keyof IAddressDTM) => (value: string) => {
    const cargoSupplier = R.selectors.bookingWizard.getCargoSupplier(this.store.getState());

    if (field === 'country') {
      if (cargoSupplier?.address) {
        this.dispatch(R.actions.bookingWizard.setCargoSupplier(ShippingPartyDTM.fromPlain({
          ...cargoSupplier,
          address: {
            ...cargoSupplier.address,
            state: '',
            [field]: value,
          },
        })));
      }

      return;
    }

    if (cargoSupplier?.address) {
      this.dispatch(R.actions.bookingWizard.setCargoSupplier(ShippingPartyDTM.fromPlain({
        ...cargoSupplier,
        address: {
          ...cargoSupplier.address,
          [field]: value,
        },
      })));
    }
  };

  public setCargoReceiverAddressField = (field: keyof IAddressDTM) => (value: string) => {
    const cargoReceiver = R.selectors.bookingWizard.getCargoReceiver(this.store.getState());

    if (field === 'country') {
      if (cargoReceiver?.address) {
        this.dispatch(R.actions.bookingWizard.setCargoReceiver(ShippingPartyDTM.fromPlain({
          ...cargoReceiver,
          address: {
            ...cargoReceiver.address,
            state: '',
            [field]: value,
          },
        })));
      }

      return;
    }

    if (cargoReceiver?.address) {
      this.dispatch(R.actions.bookingWizard.setCargoReceiver(ShippingPartyDTM.fromPlain({
        ...cargoReceiver,
        address: {
          ...cargoReceiver.address,
          [field]: value,
        },
      })));
    }
  };

  public setCargoReceiverContactField = (field: keyof IContactDTM) => (value: string) => {
    const cargoReceiver = R.selectors.bookingWizard.getCargoReceiver(this.store.getState());
    const hasPhoneErrorMessage = !!R.selectors.bookingWizard.getReceiverPhoneErrorMessage(this.store.getState())?.length;
    const hasAdditionalPhoneErrorMessage = !!R.selectors.bookingWizard.getReceiverAdditionalErrorMessage(this.store.getState())?.length;

    if (field === 'phone' && hasPhoneErrorMessage) {
      const validation = validationPhone(value);

      this.dispatch(R.actions.bookingWizard.setReceiverPhoneErrorMessage(validation.errorMessage || undefined));
    }

    if (field === 'phone2' && hasAdditionalPhoneErrorMessage) {
      const validation = validationPhone(value, { isRequired: false });

      this.dispatch(R.actions.bookingWizard.setReceiverAdditionalPhoneErrorMessage(validation.errorMessage || undefined));
    }

    if (cargoReceiver) {
      this.dispatch(R.actions.bookingWizard.setCargoReceiver(ShippingPartyDTM.fromPlain({
        ...cargoReceiver,
        contact: {
          ...(cargoReceiver.contact || {
            id: null,
            fullName: '',
            phone: '',
            phone2: '',
            email: '',
          }),
          [field]: value,
        },
      })));
    }
  };

  public setCargoSupplierContactField = (field: keyof IContactDTM) => (value: string) => {
    const cargoSupplier = R.selectors.bookingWizard.getCargoSupplier(this.store.getState());
    const hasPhoneErrorMessage = !!R.selectors.bookingWizard.getSupplierPhoneErrorMessage(this.store.getState())?.length;
    const hasAdditionalPhoneErrorMessage = !!R.selectors.bookingWizard.getSupplierAdditionalErrorMessage(this.store.getState())?.length;

    if (field === 'phone' && hasPhoneErrorMessage) {
      const validation = validationPhone(value);

      this.dispatch(R.actions.bookingWizard.setSupplierPhoneErrorMessage(validation.errorMessage || undefined));
    }

    if (field === 'phone2' && hasAdditionalPhoneErrorMessage) {
      const validation = validationPhone(value, { isRequired: false });

      this.dispatch(R.actions.bookingWizard.setSupplierAdditionalPhoneErrorMessage(validation.errorMessage || undefined));
    }

    if (cargoSupplier) {
      this.dispatch(R.actions.bookingWizard.setCargoSupplier(ShippingPartyDTM.fromPlain({
        ...cargoSupplier,
        contact: {
          ...(cargoSupplier.contact || {
            id: null,
            fullName: '',
            phone: '',
            phone2: '',
            email: '',
          }),
          [field]: value,
        },
      })));
    }
  };

  public setContainerWeightById = (id: string, weight: number) => {
    const containers = [...R.selectors.bookingWizard.getContainers(this.store.getState())];
    const targetContainerIndex = containers.findIndex((container) => container.id === id);

    if (targetContainerIndex !== -1) {
      containers.splice(targetContainerIndex, 1, ContainerDTM.fromPlain({
        ...containers[targetContainerIndex],
        estimatedWeight: weight,
      }));
    }

    this.dispatch(R.actions.bookingWizard.setContainers(containers));
    this.validateContainer(id);
  }

  public setContainerVolumeById = (id: string, volume: number) => {
    const containers = [...R.selectors.bookingWizard.getContainers(this.store.getState())];
    const targetContainerIndex = containers.findIndex((container) => container.id === id);

    if (targetContainerIndex !== -1) {
      containers.splice(targetContainerIndex, 1, ContainerDTM.fromPlain({
        ...containers[targetContainerIndex],
        estimatedVolume: volume,
      }));
    }

    this.dispatch(R.actions.bookingWizard.setContainers(containers));
    this.validateContainer(id);
  }

  public setContainerReferenceById = (id: string, referenceValue: string) => {
    const containers = [...R.selectors.bookingWizard.getContainers(this.store.getState())];
    const targetContainerIndex = containers.findIndex((container) => container.id === id);

    if (targetContainerIndex !== -1) {
      const reference = containers[targetContainerIndex].references[0] || ReferenceDTM.fromPlain({
        id: '0',
        type: 'OTHER',
        value: '',
      });

      reference.value = referenceValue;

      containers.splice(targetContainerIndex, 1, ContainerDTM.fromPlain({
        ...containers[targetContainerIndex],
        references: referenceValue ? [reference] : [],
      }));
    }

    this.dispatch(R.actions.bookingWizard.setContainers(containers));
  }

  public saveSuppliers = async () => {
    const shipmentId = R.selectors.bookingWizard.getShipmentId(this.store.getState());
    const cargoSupplier = R.selectors.bookingWizard.getCargoSupplier(this.store.getState());
    const cargoReceiver = R.selectors.bookingWizard.getCargoReceiver(this.store.getState());

    if (cargoSupplier) {
      this.validateCargoSupplier(cargoSupplier);
    }

    if (cargoReceiver) {
      this.validateCargoReceiver(cargoReceiver);
    }

    const isThereAnyPhonesErrors = R.selectors.bookingWizard.getIsThereAnyPhoneError(this.store.getState());
    const isCargoSupplierRequiredErrorVisible = R.selectors.bookingWizard.getIsCargoSupplierRequiredErrorVisible(this.store.getState());
    const isCargoReceiverRequiredErrorVisible = R.selectors.bookingWizard.getIsCargoReceiverRequiredErrorVisible(this.store.getState());

    if (isThereAnyPhonesErrors || isCargoReceiverRequiredErrorVisible || isCargoSupplierRequiredErrorVisible) {
      return;
    }

    this.dispatch(R.actions.bookingWizard.setIsLoading(true));
    this.dispatch(R.actions.bookingWizard.setIsContentUpdating(true));

    const isOriginPartner = R.selectors.bookingWizard.getIsOriginPartner(this.store.getState());

    if (cargoSupplier) {
      const loadingControls = R.selectors.bookingWizard.getSupplierLoadingControls(this.store.getState());

      const updatedCargoSupplier = await R.services.shippingParties.putShippingPartyWithContactData(+shipmentId, cargoSupplier);

      if (isOriginPartner) {
        const newLoadingControls = await this.createOrSaveLoadingControl(shipmentId, loadingControls);
        this.dispatch(R.actions.bookingWizard.setSupplierLoadingControls(newLoadingControls || loadingControls));
      }

      this.dispatch(R.actions.bookingWizard.setCargoSupplier(updatedCargoSupplier));
    }

    if (cargoReceiver) {
      const loadingControls = R.selectors.bookingWizard.getReceiverLoadingControls(this.store.getState());

      const updatedCargoReceiver = await R.services.shippingParties.putShippingPartyWithContactData(+shipmentId, cargoReceiver);

      if (isOriginPartner) {
        const newLoadingControls = await this.createOrSaveLoadingControl(shipmentId, loadingControls);
        this.dispatch(R.actions.bookingWizard.setReceiverLoadingControls(newLoadingControls || loadingControls));
      }

      this.dispatch(R.actions.bookingWizard.setCargoReceiver(updatedCargoReceiver));
    }

    const isCustomerOrg = R.selectors.bookingWizard.getIsCurrentOrganizationCustomer(this.store.getState());

    if (isCustomerOrg) {
      this.dispatch(R.actions.bookingWizard.setStep(3));
    } else {
      await this.agreeAndBook();
    }

    this.dispatch(R.actions.bookingWizard.setIsLoading(false));
    this.dispatch(R.actions.bookingWizard.setIsContentUpdating(false));
  }

  public createOrSaveLoadingControl = async (shipmentId: string, loadingControl: LoadingControlDTM | null) => {
    let newLoadingControl: LoadingControlDTM | null = null;

    if (!loadingControl) {
      return null;
    }

    if (loadingControl.id) {
      newLoadingControl = await R.services.loadingControls.editLoadingControl(shipmentId, loadingControl);
    } else {
      newLoadingControl = await R.services.loadingControls.createLoadingControl(shipmentId, loadingControl);
    }

    return newLoadingControl;
  }

  public setSuppliersStepActiveTab = (tab: string) => {
    this.dispatch(R.actions.bookingWizard.setSuppliersStepActiveTab(tab));
  }

  public validateSuppliers = () => {
    const cargoSupplier = R.selectors.bookingWizard.getCargoSupplier(this.store.getState());
    const cargoReceiver = R.selectors.bookingWizard.getCargoReceiver(this.store.getState());

    if (cargoReceiver) {
      this.validateCargoReceiver(cargoReceiver);
    }

    if (cargoSupplier) {
      this.validateCargoSupplier(cargoSupplier);
    }
  };

  private validateCargoSupplier = (cargoSupplier: ShippingPartyDTM) => {
    const loadingControls = R.selectors.bookingWizard.getSupplierLoadingControls(this.store.getState());
    const hasError = this.validateSupplierShippingParty(cargoSupplier, loadingControls);

    const { contact } = cargoSupplier;

    if (contact) {
      const { phone, phone2 } = contact;

      if (phone) {
        const validation = validationPhone(phone);

        this.dispatch(R.actions.bookingWizard.setSupplierPhoneErrorMessage(validation.errorMessage || undefined));
      }

      if (phone2) {
        const validation = validationPhone(phone2, { isRequired: false });

        this.dispatch(R.actions.bookingWizard.setSupplierAdditionalPhoneErrorMessage(validation.errorMessage || undefined));
      }
    }

    this.dispatch(R.actions.bookingWizard.setIsCargoSupplierRequiredErrorVisible(hasError));
  }

  private validateCargoReceiver = (cargoReceiver: ShippingPartyDTM) => {
    const loadingControls = R.selectors.bookingWizard.getReceiverLoadingControls(this.store.getState());
    const hasError = this.validateSupplierShippingParty(cargoReceiver, loadingControls);

    const { contact } = cargoReceiver;

    if (contact) {
      const { phone, phone2 } = contact;

      if (phone) {
        const validation = validationPhone(phone);

        this.dispatch(R.actions.bookingWizard.setReceiverPhoneErrorMessage(validation.errorMessage || undefined));
      }

      if (phone2) {
        const validation = validationPhone(phone2, { isRequired: false });

        this.dispatch(R.actions.bookingWizard.setReceiverAdditionalPhoneErrorMessage(validation.errorMessage || undefined));
      }
    }

    this.dispatch(R.actions.bookingWizard.setIsCargoReceiverRequiredErrorVisible(hasError));
  }

  public validateSupplierShippingParty = (shippingParty: ShippingPartyDTM, loadingControls: LoadingControlDTM | null) => {
    const { address, company, contact } = shippingParty;
    let hasError = false;

    if (!company?.id) {
      hasError = true;
    }

    if (!address?.address1 || !address.country || (address.country === US_COUNTRY_CODE && !address.state) || !address?.city || !address?.postalCode) {
      hasError = true;
    }

    const emailHasError = !!validationEmail(contact?.email || '').errorMessage.length;

    if (!contact?.fullName || emailHasError || !contact?.phone) {
      hasError = true;
    }

    const isOriginPartner = R.selectors.bookingWizard.getIsOriginPartner(this.store.getState());

    if (!isOriginPartner) {
      return hasError;
    }

    if (!loadingControls || !loadingControls.loadingMethod || (loadingControls.loadingMethod === EShipmentLoadingMethod.LIVE_LOAD && !loadingControls.receivingMethod)) {
      hasError = true;
    }

    return hasError;
  };

  private validateContainer = (containerId: string) => {
    const {
      seaworthyCertificate,
      number,
      estimatedWeight,
      estimatedVolume,
    } = R.selectors.bookingWizard.getContainerById(containerId)(this.store.getState());
    const hasSOC = R.selectors.bookingWizard.getHasSOC(this.store.getState());

    this.dispatch(R.actions.bookingWizard.setContainersError({
      containerId,
      errors: BookingWizardContainersErrorsDTM.createEmpty(containerId),
    }));

    if (hasSOC) {
      if (!seaworthyCertificate) {
        this.dispatch(R.actions.bookingWizard.setContainersErrorByKey({
          containerId,
          errorKey: 'certificate',
          error: ValidationErrorDTM.fromPlain({
            type: ValidationErrorType.DEFAULT,
            message: 'shipmentContainerErrors.REFERENCE_TYPE_REQUIRED_MESSAGE',
          }),
        }));
      }

      const containerNumberValidation = this.validateShipmentContainerNumber(number);

      if (containerNumberValidation) {
        this.dispatch(R.actions.bookingWizard.setContainersErrorByKey({
          containerId,
          errorKey: 'number',
          error: containerNumberValidation,
        }));
      }

      return;
    }

    if (!estimatedWeight) {
      this.dispatch(R.actions.bookingWizard.setContainersErrorByKey({
        containerId,
        errorKey: 'estimatedWeight',
        error: ValidationErrorDTM.fromPlain({
          type: ValidationErrorType.DEFAULT,
          message: 'shipmentContainerErrors.WEIGHT_REQUIRED_MESSAGE',
        }),
      }));
    }

    if (!estimatedVolume) {
      this.dispatch(R.actions.bookingWizard.setContainersErrorByKey({
        containerId,
        errorKey: 'estimatedVolume',
        error: ValidationErrorDTM.fromPlain({
          type: ValidationErrorType.DEFAULT,
          message: 'shipmentContainerErrors.VOLUME_REQUIRED_MESSAGE',
        }),
      }));
    }
  }

  private validateContainers = () => {
    const hasSOC = R.selectors.bookingWizard.getHasSOC(this.store.getState());
    const containers = R.selectors.bookingWizard.getContainers(this.store.getState());

    containers.forEach(({
      seaworthyCertificate,
      number,
      estimatedWeight,
      estimatedVolume,
      id,
    }) => {
      if (hasSOC) {
        if (!seaworthyCertificate) {
          this.dispatch(R.actions.bookingWizard.setContainersErrorByKey({
            containerId: id,
            errorKey: 'certificate',
            error: ValidationErrorDTM.fromPlain({
              type: ValidationErrorType.DEFAULT,
              message: 'shipmentContainerErrors.REFERENCE_TYPE_REQUIRED_MESSAGE',
            }),
          }));
        }

        const containerNumberValidation = this.validateShipmentContainerNumber(number);

        if (containerNumberValidation) {
          this.dispatch(R.actions.bookingWizard.setContainersErrorByKey({
            containerId: id,
            errorKey: 'number',
            error: containerNumberValidation,
          }));
        }
      } else {
        if (!estimatedWeight) {
          this.dispatch(R.actions.bookingWizard.setContainersErrorByKey({
            containerId: id,
            errorKey: 'estimatedWeight',
            error: ValidationErrorDTM.fromPlain({
              type: ValidationErrorType.DEFAULT,
              message: 'shipmentContainerErrors.WEIGHT_REQUIRED_MESSAGE',
            }),
          }));
        }

        if (!estimatedVolume) {
          this.dispatch(R.actions.bookingWizard.setContainersErrorByKey({
            containerId: id,
            errorKey: 'estimatedVolume',
            error: ValidationErrorDTM.fromPlain({
              type: ValidationErrorType.DEFAULT,
              message: 'shipmentContainerErrors.VOLUME_REQUIRED_MESSAGE',
            }),
          }));
        }
      }
    });
  }

  private validateCargosHazmat = () => {
    const cargos = R.selectors.bookingWizard.getCargos(this.store.getState());
    const shouldHaveAnyHazmats = R.selectors.bookingWizard.getShouldHaveAnyHazmats(this.store.getState());

    if (!shouldHaveAnyHazmats) {
      return;
    }

    cargos.forEach((cargo) => {
      if (cargo && cargo.id) {
        this.updateHazmatErrors(cargo.id);
      }
    });
  }

  private validateCargosRequiredFields = () => {
    const cargos = R.selectors.bookingWizard.getCargos(this.store.getState());

    cargos.forEach((cargo) => this.validateCargoRequiredFields(cargo));
  }

  private validateCargosDrayageRequiredFields = () => {
    const cargos = R.selectors.bookingWizard.getCargos(this.store.getState());

    cargos.forEach((cargo) => this.validateCargoDrayageRequiredFields(cargo));
  }

  private validateCargoDrayageRequiredFields = (cargo: CargoDTM) => {
    const {
      code,
      packagesNumber,
      packageType,
      weight,
      value,
    } = cargo;

    if (!code) {
      this.dispatch(R.actions.bookingWizard.setCargoError({
        cargoId: Number(cargo?.id),
        field: 'code',
        error: this.generateRequiredError(),
      }));
    }

    if (!value || Number(value) === 0) {
      this.dispatch(R.actions.bookingWizard.setCargoError({
        cargoId: Number(cargo?.id),
        field: 'value',
        error: this.generateRequiredError(),
      }));
    }

    if (!packagesNumber) {
      this.dispatch(R.actions.bookingWizard.setCargoError({
        cargoId: Number(cargo?.id),
        field: 'packagesNumber',
        error: this.generateRequiredError(),
      }));
    }

    if (!packageType) {
      this.dispatch(R.actions.bookingWizard.setCargoError({
        cargoId: Number(cargo?.id),
        field: 'packageType',
        error: this.generateRequiredError(),
      }));
    }

    if (!weight) {
      this.dispatch(R.actions.bookingWizard.setCargoError({
        cargoId: Number(cargo?.id),
        field: 'weight',
        error: this.generateRequiredError(),
      }));
    }
  }

  private validateCargoRequiredFields = (cargo: CargoDTM) => {
    const {
      code,
      packagesNumber,
      packageType,
      weight,
    } = cargo;

    if (!code) {
      this.dispatch(R.actions.bookingWizard.setCargoError({
        cargoId: Number(cargo?.id),
        field: 'code',
        error: this.generateRequiredError(),
      }));
    }

    if (!packagesNumber) {
      this.dispatch(R.actions.bookingWizard.setCargoError({
        cargoId: Number(cargo?.id),
        field: 'packagesNumber',
        error: this.generateRequiredError(),
      }));
    }

    if (!packageType) {
      this.dispatch(R.actions.bookingWizard.setCargoError({
        cargoId: Number(cargo?.id),
        field: 'packageType',
        error: this.generateRequiredError(),
      }));
    }

    if (!weight) {
      this.dispatch(R.actions.bookingWizard.setCargoError({
        cargoId: Number(cargo?.id),
        field: 'weight',
        error: this.generateRequiredError(),
      }));
    }
  }

  private generateRequiredError = () => ValidationErrorDTM.fromPlain({
    type: ValidationErrorType.DEFAULT,
    message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
  });

  private updateHazmatStatus = (cargoId: number, forceToBeHazmat?: boolean) => {
    const cargos = R.selectors.bookingWizard.getCargos(this.store.getState());
    const cargo = cargos.find(({ id }) => id === cargoId);

    if (!cargo) {
      return;
    }

    const isHazmat = this.getHazmatStatus(cargo);

    this.dispatch(R.actions.bookingWizard.setIsHazmat({
      isHazmat: forceToBeHazmat || isHazmat,
      cargoId,
    }));
  }

  private checkCargoHazmatEmptynessCriteria = () => {
    const cargos = R.selectors.bookingWizard.getCargos(this.store.getState());
    const shouldHaveAnyHazmats = R.selectors.bookingWizard.getShouldHaveAnyHazmats(this.store.getState());

    if (!shouldHaveAnyHazmats) {
      return;
    }

    const areAllCargoHazmatsEmpty = cargos.every(({
      imoClass,
      unNumber,
      packingGroup,
      shippingName,
      msdsDocument,
    }) => !unNumber && !imoClass && !packingGroup && !shippingName && !msdsDocument.length);

    this.dispatch(R.actions.bookingWizard.setIsHazmatErrorVisible(areAllCargoHazmatsEmpty));
  }

  private validateIfContainersValueCostIsExceeded = (): boolean => {
    const containers = R.selectors.bookingWizard.getContainers(this.store.getState());
    const cargos = R.selectors.bookingWizard.getCargos(this.store.getState());

    const maxAllowedCost = containers.length * CONTAINER_MAX_COST_VALUE;
    const actualCost = cargos.reduce((prev, current) => (Number(current.value) || 0) + prev, 0);

    return actualCost >= maxAllowedCost;
  }

  private updateHazmatErrors = (cargoId: number) => {
    const cargos = R.selectors.bookingWizard.getCargos(this.store.getState());
    const cargo = cargos.find(({ id }) => id === cargoId);

    if (!cargo) {
      return;
    }

    const {
      isHazmat,
      imoClass,
      unNumber,
      packingGroup,
      shippingName,
      msdsDocument,
    } = cargo;

    if (!isHazmat) {
      this.dispatch(R.actions.bookingWizard.clearHazmatErrors(cargoId));
      return;
    }

    const errors = CargoErrorsDTM.fromPlain(validateHazmat(
      unNumber,
      imoClass,
      packingGroup,
      shippingName,
      msdsDocument,
    ));

    if (!errors.hasErrors()) {
      this.dispatch(R.actions.bookingWizard.setIsHazmatErrorVisible(false));
    }

    if (errors.hasErrors() && cargo.id) {
      const isToggled = R.selectors.bookingWizard.getIsHazmatToggledByCargoId(cargo.id)(this.store.getState());

      if (!isToggled) {
        this.dispatch(R.actions.bookingWizard.addToggledHazmatCargoId(cargo.id));
      }
    }

    this.dispatch(R.actions.bookingWizard.setHazmatErrors({
      ...errors,
      cargoId,
    }));
  }

  private updateCargoErrors = (index: number) => {
    const cargo = R.selectors.bookingWizard.getCargos(this.store.getState())[index];

    if (!cargo) {
      return;
    }

    const {
      code,
      name,
      packageType,
      packagesNumber,
      weight,
      references,
    } = cargo;

    let cargoErrors = CargoErrorsDTM.fromPlain({
      code: validateRequiredField(code),
      name: validateRequiredField(name),
      packageType: validateRequiredField(packageType),
      packagesNumber: validateRequiredField(packagesNumber),
      weight: validateRequiredField(weight),
      references: validateReferences(references),
    });

    if (cargo.isHazmat) {
      const {
        unNumber,
        imoClass,
        packingGroup,
        shippingName,
        msdsDocument,
      } = cargo;

      cargoErrors = CargoErrorsDTM.fromPlain({
        ...cargoErrors,
        ...validateHazmat(
          unNumber,
          imoClass,
          packingGroup,
          shippingName,
          msdsDocument,
        ),
      });
    }

    this.dispatch(R.actions.bookingWizard.setCargoErrors({
      index,
      errors: cargoErrors,
    }));
  };

  private getHazmatStatus = (cargo: HazmatDTM) => {
    const {
      unNumber,
      imoClass,
      packingGroup,
      shippingName,
      msdsDocument,
    } = cargo;

    const msdsStatus = (
      msdsDocument
      && !!msdsDocument.length
      && msdsDocument[0].status === 'done'
      && !!msdsDocument[0].response.id
      && !!msdsDocument[0].response.name
      && !!msdsDocument[0].response.type
    );

    const isHazmat = (
      msdsStatus
      || !!imoClass
      || !!unNumber
      || !!packingGroup
      || !!shippingName
    );

    return isHazmat;
  }

  private validateShipmentContainerNumber = (containerNumber?: string | null) => {
    if (!containerNumber) {
      return ValidationErrorDTM.fromPlain({
        type: ValidationErrorType.DEFAULT,
        message: 'shipmentContainerErrors.REFERENCE_TYPE_REQUIRED_MESSAGE',
      });
    }

    if (!containerNumber.match(/^[a-zA-Z]{4}\d{7}$/)) {
      return ValidationErrorDTM.fromPlain({
        type: ValidationErrorType.ALERT,
        message: 'shipmentContainerErrors.INVALID_CONTAINER_NUMBER',
      });
    }

    return undefined;
  }
}
