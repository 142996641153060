import React, {
  FC,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  TeamOutlined,
  EnvironmentOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { COUNTRIES_SELECT_OPTIONS } from 'app-wrapper/repository/store/CountriesList/countries';
import { ShippingPartyDTM, ShippingPartyReference } from 'shipment-operations/models/dtm';
import { EditButton } from 'app-wrapper/view/components';
import {
  MissingTextValue,
  RowSpaceBetween,
  FlexCol,
  GapVerticalContainerStyled,
} from 'app-wrapper/view/guideline';

import {
  Wrap,
  Content,
  ContentRow,
  CompanyInfoBlock,
  Title,
  InfoBlock,
  InfoCard,
  Divider,
  ReferencesBlock,
  References,
  Text,
  TextValue,
  Tag,
  // ArrowWrap,
  // CollapseWrap,
} from './ShippingPartySection.styled';

const VISIBLE_REFERENCES_LIMIT = 2;

interface IShippingPartySectionProps {
  shippingParty: ShippingPartyDTM;
  onEdit?: () => void;
  title: string;
  addressTitle: string;
}

export const ShippingPartySection: FC<IShippingPartySectionProps> = ({
  shippingParty,
  onEdit,
  title,
  addressTitle,
}) => {
  const { t } = useTranslation();
  // const [isCollapsed, setIsCollapsed] = useState(false);

  const {
    company,
    address,
    contact,
    references,
  } = shippingParty;
  const validReferences = references.filter(({ value }) => !!value.length);

  const country = COUNTRIES_SELECT_OPTIONS?.find(({ value }) => value === address?.country);

  const [hiddenReferences, visibleReferences] = useMemo(() => {
    const hidden: ShippingPartyReference[] = [...validReferences];
    const visible: ShippingPartyReference[] = hidden.splice(0, VISIBLE_REFERENCES_LIMIT);

    return [hidden, visible];
  }, [validReferences]);

  // const toggleCollapse = useCallback(() => setIsCollapsed((_isCollapsed) => !_isCollapsed), [setIsCollapsed]);

  return (
    <Wrap>
      <GapVerticalContainerStyled>
        <RowSpaceBetween>
          <Title>
            {title}
          </Title>

          {onEdit ? (
            <EditButton onClick={onEdit} />
          ) : null}
        </RowSpaceBetween>

        <Content>
          <ContentRow>
            <CompanyInfoBlock>
              {/* <ArrowWrap onClick={toggleCollapse}> */}
              {/*  <VerticalFormItemSvg */}
              {/*    style={{ */}
              {/*      color: isCollapsed ? themesColors?.primaryBranding6 : themesColors?.characterBrandingTitle85, */}
              {/*    }} */}
              {/*    rotate={isCollapsed ? 0 : 270} */}
              {/*  /> */}
              {/* </ArrowWrap> */}

              <InfoBlock>
                <InfoCard
                  icon={<TeamOutlined />}
                  title={t('Company Name')}
                  value={company?.name || <MissingTextValue />}
                  isTextBigger
                />
              </InfoBlock>
            </CompanyInfoBlock>

            <InfoBlock>
              <InfoCard
                icon={<EnvironmentOutlined />}
                title={addressTitle}
                value={address ? (
                  <>
                    {address.address1} {address.address2 ? `, ${address.address2}` : ''}<br />
                    {`${address.city}, ${address.state}, ${address.postalCode}`} <br />
                    {country?.label || address.country}
                  </>
                ) : <MissingTextValue />}
                isTextBigger
              />
            </InfoBlock>

            <InfoBlock>
              <InfoCard
                icon={<UserOutlined />}
                title={t('Contact Person')}
                value={contact ? (
                  <>
                    {contact?.fullName}<br />
                    {contact?.email}<br />
                    {contact?.phone}
                  </>
                ) : <MissingTextValue />}
                isTextBigger
              />
            </InfoBlock>

            <Divider
              type="vertical"
              dashed
            />

            <ReferencesBlock>
              <FlexCol>
                <Text>{t('References')}</Text>

                {validReferences.length ? (
                  <References>
                    {visibleReferences.map((reference) => (
                      <Tag key={reference.id}>
                        {reference.value}
                      </Tag>
                    ))}

                    {hiddenReferences.length ? (
                      <Tag>
                        + {hiddenReferences.length}
                      </Tag>
                    ) : null}
                  </References>
                ) : <MissingTextValue />}
              </FlexCol>

              <FlexCol>
                <Text>{t('Tax ID')}</Text>

                {company?.taxId ? (
                  <TextValue>
                    {company.taxId}
                  </TextValue>
                ) : <MissingTextValue />}
              </FlexCol>
            </ReferencesBlock>
          </ContentRow>

          {/* <CollapseWrap> */}
          {/*  <Collapse */}
          {/*    activeKey={isCollapsed ? '1' : ''} */}
          {/*  > */}
          {/*    <Panel header="" key="1" extra={null}> */}
          {/*      collapse value */}
          {/*    </Panel> */}
          {/*  </Collapse> */}
          {/* </CollapseWrap> */}
        </Content>
      </GapVerticalContainerStyled>
    </Wrap>
  );
};
