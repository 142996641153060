import React, {
  FC,
  useMemo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Moment } from 'moment';
import get from 'lodash/fp/get';
import DatePicker from 'antd/es/date-picker';
import Alert from 'antd/es/alert';
import Modal from 'antd/es/modal';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { useIsExportOrImport } from 'app-wrapper/hooks';
import { LinkSvg, UnlockSvg } from 'app-wrapper/view/icons';
import { Button } from 'app-wrapper/view/components';
import { DateDtm } from 'app-wrapper/models/dtm';
import {
  FREE_DATE,
  ACTUAL,
  EXPECTED,
  DEFINED_DATE,
  PermissionAttributePolicy,
  MISSING_DATES_SEARCH_PARAM,
} from 'shipment-operations/constants';
import { InnerSteps } from 'shipment-operations/view/pages/ShipmentTransportationTracker/components/InnerSteps';
import { ShipmentTrackerTableDTM, TrackerContainersTableDTM } from 'shipment-operations/models/dtm';
import { useToggleCollapse } from 'shipment-operations/view/hooks';

import {
  StyledExpandIcon,
} from 'shipment-operations/view/pages/ShipmentTransportationCharges/components/CustomTable/CustomTable.styled';
import { ContainerTitleWrapper, ContainerType } from 'shipment-operations/view/components/ExpandPanel/ExpandPanel.styled';

import { Footer } from 'app-wrapper/view/guideline';
import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';
import {
  Container, StyledTable, StyledButton, StyledType, LinkWrapper,
} from './ContainersTable.styled';

export interface IContainersTableProps {
  isLoading: boolean
  error: boolean
  containers: TrackerContainersTableDTM[]
  fetchData: (shipmentId?: string) => void
  onChangeDate: (id: string, type: string, date: Moment) => void
  isUpdatedDates: boolean
  onResetChanges: () => void
  onPostDates: (shipmentId?: string) => void
  isLoadingUpdate: boolean
  trackerAvailability?: PermissionAttributePolicy
  trackerWarningsAvailability?: PermissionAttributePolicy
  openLinkContainer: (planId: number) => void
  onUnlinkContainer: (shipmentId: string, containerId: number) => void
  showUntrackedWarning: boolean
  isDrayage?: boolean
  isCommon?: boolean
}

const TIME_FORMAT = 'D MMM YYYY, HH:mm';

const ContainersTableComponent: FC<IContainersTableProps> = ({
  isLoading, error, containers, fetchData, onChangeDate, isUpdatedDates, openLinkContainer, showUntrackedWarning, isCommon,
  onResetChanges, onPostDates, isLoadingUpdate, trackerAvailability, trackerWarningsAvailability, onUnlinkContainer, isDrayage,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isExport, isImport, isExportOrImport } = useIsExportOrImport();

  const { shipmentId } = useParams<'shipmentId'>();
  const { t } = useTranslation();
  const { openedKeys, onToggleCollapse } = useToggleCollapse();

  const [isMissingDatesCase, setIsMissingDatesCase] = useState(Boolean(searchParams.get(MISSING_DATES_SEARCH_PARAM)));

  useEffect(() => {
    const indexesToToggle: number[] = [];

    if (containers.length && isMissingDatesCase) {
      containers.forEach((container, index) => {
        const { events } = container;

        if (events) {
          events.forEach((step) => {
            if (step.withWarning && !step.actualDate) {
              // adding container index containing this missing date
              indexesToToggle.push(index);
            }
          });
        }
      });

      // finding first container index with missing date in order to toggle
      const firstIndex = Math.min(...indexesToToggle);
      setIsMissingDatesCase(false);
      onToggleCollapse(firstIndex);
    }

    setSearchParams('');
  }, [containers]);

  const isAbleToEdit = useMemo(() => trackerAvailability === PermissionAttributePolicy.WRITE, [trackerAvailability]);
  const isUpdateDisabled = useMemo(() => !isUpdatedDates || !isAbleToEdit, [isAbleToEdit, isUpdatedDates]);

  const handleChangeDate = useCallback((id, type, date) => {
    onChangeDate(id, type, date);
  }, []);

  const renderCalendar = useCallback((record, type, selectedKey, date?: DateDtm) => {
    const key = get(['key'], record);
    const editable = isAbleToEdit && get(['editable'], record);
    const dateType = get([selectedKey], record);
    const eventId = get(['eventId'], record);

    if (dateType === FREE_DATE) {
      return (
        <DatePicker
          allowClear={false}
          format={TIME_FORMAT}
          disabled={openedKeys.includes(key) || !editable}
          showTime
          onChange={(newDate) => handleChangeDate(eventId, type, newDate)}
        />
      );
    }

    return dateType === DEFINED_DATE ? (
      <DatePicker
        allowClear={false}
        format={TIME_FORMAT}
        value={date?.getDateAsMomentWithOffset()}
        disabled={openedKeys.includes(key) || !editable}
        showTime
        onChange={(newDate) => handleChangeDate(eventId, type, newDate)}
      />
    ) : null;
  }, [openedKeys, containers, isAbleToEdit]);

  const renderEventName = useCallback((name, record, arg, isNextEvent: boolean = false) => {
    const previousEventDrayage = get(['previousEventDrayage'], record);
    if (isNextEvent) {
      return (name ? t(`eventsCodes.${name}`) : '—');
    }
    if (isExportOrImport) {
      return previousEventDrayage ? t(`eventsCodes.${previousEventDrayage}`) : '—';
    }
    return (name ? t(`eventsCodes.${name}`) : '—');
  }, []);

  const handleFetchData = useCallback(() => {
    fetchData(shipmentId);
  }, []);

  const handlePostDates = useCallback(() => {
    onPostDates(shipmentId);
  }, []);

  const postUnlink = useCallback((containerId) => {
    if (shipmentId) {
      onUnlinkContainer(shipmentId, containerId);
    }
    return new Promise((resolve) => {
      setTimeout(resolve, 1500);
    });
  }, []);

  const handleUnlink = useCallback((containerId) => {
    Modal.confirm({
      title: t('Are you sure you want to unlink this container?'),
      okText: t('yes'),
      cancelText: t('no'),
      onOk: () => postUnlink(containerId),
      autoFocusButton: null,
    });
  }, []);

  const linkedColumn = {
    title: t('Link'),
    dataIndex: 'link',
    key: 'link',
    width: '10%',
    render: (number: string, record: ShipmentTrackerTableDTM | {}) => {
      const isLinked = get(['isLinked'], record);
      const id = get(['id'], record);
      const planId = get(['planId'], record);
      if (isLinked) {
        return (
          <LinkWrapper onClick={() => handleUnlink(id)}>
            <UnlockSvg fill="rgba(0, 0, 0, 0.25)" />
            <span style={{ marginLeft: '8px' }}>{t('Unlink')}</span>
          </LinkWrapper>
        );
      }
      if (!isLinked && showUntrackedWarning) {
        return (
          <LinkWrapper onClick={() => openLinkContainer(planId)}>
            <LinkSvg fill="rgba(0, 0, 0, 0.25)" />
            <span style={{ marginLeft: '8px' }}>{t('Link')}</span>
          </LinkWrapper>
        );
      }
      return null;
    },
  };

  const columns = useMemo(() => [
    {
      title: t('container'),
      dataIndex: 'number',
      key: 'number',
      width: isDrayage ? '14%' : '17.5%',
      render: (number: string, record: ShipmentTrackerTableDTM | {}) => {
        const key = get(['key'], record);
        const type = get(['type'], record);
        return (
          <StyledType onClick={() => onToggleCollapse(key)} isOpen={openedKeys.includes(key)}>
            <StyledExpandIcon isActive={openedKeys.includes(key)} />
            <ContainerTitleWrapper>
              <ContainerType active={openedKeys.includes(key)}>
                {type} &apos;
              </ContainerType>
            </ContainerTitleWrapper>
            {number || t('Number pending entry')}
          </StyledType>
        );
      },
    },
    {
      title: t('previousEvent'),
      dataIndex: 'previousEvent',
      key: 'previousEvent',
      width: isDrayage ? '14%' : '17.5%',
      render: renderEventName,
    },
    {
      title: t('Date'),
      dataIndex: 'date',
      key: 'date',
      width: isDrayage ? '14%' : '17.5%',
      render: (date: DateDtm) => (date ? date?.getDateAsMomentWithOffset().format(TIME_FORMAT) : '—'),
    },
    {
      title: t('nextEvent'),
      dataIndex: 'nextEvent',
      key: 'nextEvent',
      width: isDrayage ? '14%' : '17.5%',
      render: (name: string, record: TrackerContainersTableDTM | {}, arg) => renderEventName(name, record, arg, true),
    },
    {
      title: t('estimatedDate'),
      dataIndex: 'estimatedDate',
      key: 'estimatedDate',
      width: isDrayage ? '14%' : '17.5%',
      render: (date: DateDtm, record: TrackerContainersTableDTM | {}) => (isCommon ? (date?.getDateAsMomentWithOffset().format(TIME_FORMAT) || '—') : renderCalendar(record, EXPECTED, 'estimatedDateType', date)),
    },
    {
      title: t('actualDate'),
      dataIndex: 'actualDate',
      key: 'actualDate',
      width: isDrayage ? '14%' : '17.5%',
      render: (date: DateDtm, record: TrackerContainersTableDTM | {}) => (isCommon ? (date?.getDateAsMomentWithOffset().format(TIME_FORMAT) || '—') : renderCalendar(record, ACTUAL, 'actualDateType', date)),
    },
    (isDrayage && !isCommon) ? linkedColumn : {},
  ], [openedKeys]);

  const expandRender = (record: TrackerContainersTableDTM | {}) => {
    const linkedEvents = get(['events'], record);
    const editable = isAbleToEdit && get(['editable'], record);

    let events;
    if (isExport) {
      events = linkedEvents.slice(0, 6);
    }
    if (isImport) {
      events = linkedEvents.slice(-6);
    }

    if (!isExport && !isImport) {
      events = linkedEvents;
    }

    return (
      <InnerSteps
        data={events}
        editable={editable}
        onChangeDate={onChangeDate}
        trackerWarningsAvailability={trackerWarningsAvailability}
        isCommon={isCommon}
      />
    );
  };

  if (error) {
    return (
      <Container>
        <Alert
          message={t('creditNote.errorMsg')}
          description={t('creditNote.errorDescription')}
          type="error"
          action={(
            <StyledButton size="middle" type="primary" onClick={handleFetchData}>
              {t('reload')}
            </StyledButton>
          )}
        />
      </Container>
    );
  }

  if (isLoading) {
    return (
      <SkeletonSection height="244px" />
    );
  }

  return (
    <>
      <Container>
        <StyledTable
          columns={columns}
          dataSource={containers}
          pagination={false}
          expandedRowClassName={() => 'customExpandRow'}
          expandable={{
            expandedRowRender: expandRender,
            expandedRowKeys: openedKeys,
            expandIconColumnIndex: -1,
          }}
        />
      </Container>
      {isUpdatedDates && (
        <Footer>
          <Button
            disabled={!isAbleToEdit}
            type="default"
            onClick={onResetChanges}
            size="large"
          >
            {t('Discard Changes')}
          </Button>
          <Button
            htmlType="submit"
            size="large"
            style={{ marginLeft: '12px' }}
            disabled={isUpdateDisabled}
            onClick={handlePostDates}
            loading={isLoadingUpdate}
          >
            {t('Update Tracker')}
          </Button>
        </Footer>
      )}
    </>
  );
};

export { ContainersTableComponent };
