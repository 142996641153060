import {
  IsArray,
  IsBoolean, IsDefined, IsNumber, IsObject, IsOptional, IsString, ValidateNested,
} from 'class-validator';
import { Type } from 'class-transformer';

import { DefaultFieldErrors, IDefaultFieldErrors } from 'monetary/models/errors';
import { BaseDTM } from 'proto/BaseDTM';

import {
  ILocationDTM, IRouteLegDTM, LocationDTM, RouteLegDTM,
} from 'shipment-operations/models/dtm';
import {
  FreightQuotaContentCommodity, FreightQuotaContentDTM, FreightQuotaPageableDTM, FreightRFRQuotaContentDTM, FreightRFRQuotaContentParamsDTM, FreightRFRQuotaScheduleDTM, FreightSelectFieldCoordinatesDTM,
} from '../../Freight';

// TODO: changed this file after billing edit interface

interface IRFQServiceByIdContentRoutesLocationDTM {
  id: number
  type: string
  placeId?: string
  country?: {
    code?: string
    name?: string
  }
  state?: {
    code?: string
    name?: string
  }
  city?: string
  name?: string
  address?: string
  postalCode?: string
  code?: string
  timeZoneId?: string
  coordinates?: FreightSelectFieldCoordinatesDTM
}

class RFQServiceByIdContentRoutesLocationDTM extends BaseDTM<IRFQServiceByIdContentRoutesLocationDTM> {
  @IsDefined()
  @IsNumber()
  id: number;

  @IsDefined()
  @IsString()
  type: string;

  @IsOptional()
  @IsString()
  placeId?: string;

  @IsOptional()
  @IsObject()
  country?: {
    code?: string
    name?: string
  };

  @IsOptional()
  @IsObject()
  state?: {
    code?: string
    name?: string
  };

  @IsOptional()
  @IsString()
  city?: string;

  @IsOptional()
  @IsString()
  name?: string;

  @IsOptional()
  @IsString()
  address?: string;

  @IsOptional()
  @IsString()
  postalCode?: string;

  @IsOptional()
  @IsString()
  code?: string;

  @IsOptional()
  @IsString()
  timeZoneId?: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightSelectFieldCoordinatesDTM)
  coordinates?: FreightSelectFieldCoordinatesDTM;
}

export interface IRFQServiceByIdContentRoutesLocationOriginDTM {
  id: number;
  type: string;
  placeId?: string;
  country?: {
    code?: string;
    name?: string;
  };
  state?: {
    code?: string;
    name?: string;
  };
  city?: string;
  name?: string;
  code?: string;
  address?: string;
  postalCode?: string;
  timeZoneId?: string;
  coordinates?: FreightSelectFieldCoordinatesDTM;
}

export class RFQServiceByIdContentRoutesLocationOriginDTM extends BaseDTM<IRFQServiceByIdContentRoutesLocationOriginDTM> {
  @IsDefined()
  @IsNumber()
  id: number;

  @IsDefined()
  @IsString()
  type: string;

  @IsOptional()
  @IsString()
  placeId?: string;

  @IsOptional()
  @IsObject()
  country?: {
    code?: string
    name?: string
  };

  @IsOptional()
  @IsObject()
  state?: {
    code?: string
    name?: string
  };

  @IsOptional()
  @IsString()
  city?: string;

  @IsOptional()
  @IsString()
  name?: string;

  @IsOptional()
  @IsString()
  address?: string;

  @IsOptional()
  @IsString()
  postalCode?: string;

  @IsOptional()
  @IsString()
  code?: string;

  @IsOptional()
  @IsString()
  timeZoneId?: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightSelectFieldCoordinatesDTM)
  coordinates?: FreightSelectFieldCoordinatesDTM;
}

interface IRFQServiceByIdContentSchedulesChargesCodeDTM {
  code: string
  description: string
  mode: string
  occurrence: string
  type: string
  subType: string
}

class RFQServiceByIdContentSchedulesChargesCodeDTM extends BaseDTM<IRFQServiceByIdContentSchedulesChargesCodeDTM> {
  @IsDefined()
  @IsString()
  code: string;

  @IsDefined()
  @IsString()
  description: string;

  @IsDefined()
  @IsString()
  mode: string;

  @IsDefined()
  @IsString()
  occurrence: string;

  @IsDefined()
  @IsString()
  type: string;

  @IsDefined()
  @IsString()
  subType: string;
}

interface IRFQServiceByIdContentSchedulesChargesCodeApplianceRangeDTM {
  minValue?: number
  maxValue?: number
}

class RFQServiceByIdContentSchedulesChargesCodeApplianceRangeDTM extends BaseDTM<IRFQServiceByIdContentSchedulesChargesCodeApplianceRangeDTM> {
  @IsDefined()
  @IsNumber()
  minValue?: number;

  @IsDefined()
  @IsNumber()
  maxValue?: number;
}

interface IRFQServiceByIdContentSchedulesChargesDTM {
  unitType?: string
  subjectTo?: string
  currency: string
  costPerUnit: number
  numberOfUnits: number
  totalCost: number
  containerId?: number
  contractId?: number
  transportationIds: number[]
  priceBy: string
  designation: string
  applied: boolean
  measureBy: string
  chargeCode: RFQServiceByIdContentSchedulesChargesCodeDTM
  applianceRange?: RFQServiceByIdContentSchedulesChargesCodeApplianceRangeDTM
}

class RFQServiceByIdContentSchedulesChargesDTM extends BaseDTM<IRFQServiceByIdContentSchedulesChargesDTM> {
  @IsOptional()
  @IsString()
  unitType?: string;

  @IsOptional()
  @IsString()
  subjectTo?: string;

  @IsDefined()
  @IsString()
  currency: string;

  @IsDefined()
  @IsNumber()
  costPerUnit: number;

  @IsDefined()
  @IsNumber()
  numberOfUnits: number;

  @IsDefined()
  @IsNumber()
  totalCost: number;

  @IsOptional()
  @IsNumber()
  containerId?: number;

  @IsOptional()
  @IsNumber()
  contractId?: number;

  @IsOptional()
  @IsArray()
  transportationIds: number[];

  @IsDefined()
  @IsString()
  priceBy: string;

  @IsDefined()
  @IsString()
  designation: string;

  @IsDefined()
  @IsBoolean()
  applied: boolean;

  @IsDefined()
  @IsString()
  measureBy: string;

  @IsDefined()
  @ValidateNested()
  @Type(() => RFQServiceByIdContentSchedulesChargesCodeDTM)
  chargeCode: RFQServiceByIdContentSchedulesChargesCodeDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => RFQServiceByIdContentSchedulesChargesCodeApplianceRangeDTM)
  applianceRange?: RFQServiceByIdContentSchedulesChargesCodeApplianceRangeDTM;
}

export interface IRFQServiceByIdContentRoutesTransportation {
  id: number
  transport: {
    number?: string
    name: string
    type: string
  }
  voyageCode?: string
  schedule: {
    type: string
    departureTime: string
    carrierArrivalTime: string
    arrivalTime: string
    carrierDepartureTime: string
    transitDuration: number
    terminalCutOff?: string
    documentCutOff?: string
    dropTime?: string
    pickupTime?: string
    hazmatCutOff?: string
    cyAvailable?: string
    ctoAvailable?: string
    vgmCutOff?: string
  }
  arrivalTerminal?: {
    name?: string
  }
  departureTerminal?: {
    name?: string
  }
  containers: number[]
  transportLeg: number
  leg?: IRouteLegDTM
  location?: ILocationDTM
  type?: string;
}

export class RFQServiceByIdContentRoutesTransportation extends BaseDTM<IRFQServiceByIdContentRoutesTransportation> {
  @IsDefined()
  @IsNumber()
  id: number;

  @IsDefined()
  @IsObject()
  transport: {
    number?: string;
    name: string;
    type: string;
  };

  @IsOptional()
  @IsString()
  voyageCode?: string;

  @IsDefined()
  @IsObject()
  schedule: {
    type: string;
    departureTime: string;
    carrierDepartureTime: string;
    arrivalTime: string;
    carrierArrivalTime: string;
    transitDuration: number;
    terminalCutOff?: string;
    documentCutOff?: string;
    dropTime?: string;
    pickupTime?: string;
    hazmatCutOff?: string;
    cyAvailable?: string;
    ctoAvailable?: string;
    vgmCutOff?: string;
  };

  @IsDefined()
  @IsObject()
  arrivalTerminal?: {
    name: string;
  };

  @IsDefined()
  @IsObject()
  departureTerminal?: {
    name: string;
  };

  @IsDefined()
  @IsArray()
  containers: number[];

  @IsDefined()
  @IsNumber()
  transportLeg: number;

  @IsOptional()
  @ValidateNested()
  @Type(() => RouteLegDTM)
  leg?: RouteLegDTM;

  @IsOptional()
  @Type(() => LocationDTM)
  location?: LocationDTM

  @IsOptional()
  @IsString()
  type?: string;
}

interface IRFQServiceByIdContentSchedulesDTM {
  id: number
  oceanScheduleId: number
  recommended: boolean
  departureTime: string
  arrivalTime: string
  totalDuration: number
  terminalCutOff: string
  documentCutOff: string
  hazmatCutOff?: string
  charges: RFQServiceByIdContentSchedulesChargesDTM[]
  transportations: RFQServiceByIdContentRoutesTransportation[]
}

class RFQServiceByIdContentSchedulesDTM extends BaseDTM<IRFQServiceByIdContentSchedulesDTM> {
  @IsDefined()
  @IsNumber()
  id: number;

  @IsDefined()
  @IsNumber()
  oceanScheduleId: number;

  @IsDefined()
  @IsBoolean()
  recommended: boolean;

  @IsDefined()
  @IsString()
  departureTime: string;

  @IsDefined()
  @IsString()
  arrivalTime: string;

  @IsDefined()
  @IsNumber()
  totalDuration: number;

  @IsDefined()
  @IsString()
  terminalCutOff: string;

  @IsDefined()
  @IsString()
  documentCutOff: string;

  @IsOptional()
  @IsString()
  hazmatCutOff?: string;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => RFQServiceByIdContentSchedulesChargesDTM)
  charges: RFQServiceByIdContentSchedulesChargesDTM[];

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => RFQServiceByIdContentRoutesTransportation)
  transportations: RFQServiceByIdContentRoutesTransportation[];
}

interface IRFQServiceByIdContentRoutesLegsDTM {
  id: number;
  phase: string;
  arrivalLocation: RFQServiceByIdContentRoutesLocationDTM;
  departureLocation: RFQServiceByIdContentRoutesLocationDTM;
}

class RFQServiceByIdContentRoutesLegsDTM extends BaseDTM<IRFQServiceByIdContentRoutesLegsDTM> {
  @IsDefined()
  @IsNumber()
  id: number;

  @IsDefined()
  @IsString()
  phase: string;

  @IsDefined()
  @ValidateNested()
  @Type(() => RFQServiceByIdContentRoutesLocationDTM)
  arrivalLocation: RFQServiceByIdContentRoutesLocationDTM;

  @IsDefined()
  @ValidateNested()
  @Type(() => RFQServiceByIdContentRoutesLocationDTM)
  departureLocation: RFQServiceByIdContentRoutesLocationDTM;
}

interface IRFQServiceByIdContentRoutesDTM {
  origin: RFQServiceByIdContentRoutesLocationOriginDTM
  destination: RFQServiceByIdContentRoutesLocationOriginDTM
  legs: RFQServiceByIdContentRoutesLegsDTM[]
  containerId?: number
  containerIds?: number[]
}

class RFQServiceByIdContentRoutesDTM extends BaseDTM<IRFQServiceByIdContentRoutesDTM> {
  @IsDefined()
  @ValidateNested()
  @Type(() => RFQServiceByIdContentRoutesLocationOriginDTM)
  origin: RFQServiceByIdContentRoutesLocationOriginDTM;

  @IsDefined()
  @ValidateNested()
  @Type(() => RFQServiceByIdContentRoutesLocationOriginDTM)
  destination: RFQServiceByIdContentRoutesLocationOriginDTM;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => RFQServiceByIdContentRoutesLegsDTM)
  legs: RFQServiceByIdContentRoutesLegsDTM[];

  @IsOptional()
  @IsNumber()
  containerId?: number;

  @IsOptional()
  @IsArray()
  containerIds?: number[];
}

interface IRFQServiceByIdContentContractDTM {
  id: number
  number: string
  name: string
  scac: string
}

class RFQServiceByIdContentContractDTM extends BaseDTM<IRFQServiceByIdContentContractDTM> {
  @IsDefined()
  @IsNumber()
  id: number;

  @IsDefined()
  @IsString()
  number: string;

  @IsDefined()
  @IsString()
  name: string;

  @IsDefined()
  @IsString()
  scac: string;
}

interface IRFQServiceByIdContentContainerDTM {
  id: number
  type: string
  ownContainer: boolean
  rateId: number
  weight?: number
  volume?: number
  commodities: FreightQuotaContentCommodity[]
}

class RFQServiceByIdContentContainerDTM extends BaseDTM<IRFQServiceByIdContentContainerDTM> {
  @IsDefined()
  @IsNumber()
  id: number;

  @IsDefined()
  @IsString()
  type: string;

  @IsDefined()
  @IsBoolean()
  ownContainer: boolean;

  @IsDefined()
  @IsNumber()
  rateId: number;

  @IsOptional()
  @IsNumber()
  weight: number;

  @IsOptional()
  @IsNumber()
  volume: number;

  @IsDefined()
  @IsArray()
  commodities: FreightQuotaContentCommodity[];
}

interface IRFQServiceByIdContentDTM {
  id: number
  cost: {
    totalCost: number
    originTotalCost: number
    freightTotalCost: number
    destinationTotalCost: number
  }
  routes: RFQServiceByIdContentRoutesDTM[]
  schedules: RFQServiceByIdContentSchedulesDTM[]
  contracts: RFQServiceByIdContentContractDTM[]
  containers: RFQServiceByIdContentContainerDTM[]
  customer?: {
    addressId?: number
    companyId?: number
    contactId?: number
  }
  validPeriod?: {
    from?: string
    to?: string
  }
}

export class RFQServiceByIdContentDTM extends BaseDTM<IRFQServiceByIdContentDTM> {
  @IsDefined()
  @IsNumber()
  id: number;

  @IsDefined()
  @IsObject()
  cost: {
    totalCost: number
    originTotalCost: number
    freightTotalCost: number
    destinationTotalCost: number
  };

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => RFQServiceByIdContentRoutesDTM)
  routes: RFQServiceByIdContentRoutesDTM[];

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => RFQServiceByIdContentSchedulesDTM)
  schedules: RFQServiceByIdContentSchedulesDTM[];

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => RFQServiceByIdContentContractDTM)
  contracts: RFQServiceByIdContentContractDTM[];

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => RFQServiceByIdContentContainerDTM)
  containers: RFQServiceByIdContentContainerDTM[];

  @IsOptional()
  @IsObject()
  customer?: {
    addressId?: number
    companyId?: number
    contactId?: number
  };

  @IsOptional()
  @IsObject()
  validPeriod?: {
    from?: string
    to?: string
  };
}

export interface IRFQQuotasDTM {
  content: FreightQuotaContentDTM[]
  pageable: FreightQuotaPageableDTM
  last: boolean
  totalPages: number
  totalElements: number
  size: number
  number: number
  sort: {
    empty: boolean
    sorted: boolean
    unsorted: boolean
  },
  first: boolean
  numberOfElements: number
  empty: boolean
}

export class RFQQuotasDTM extends BaseDTM<IRFQQuotasDTM> {
  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => FreightQuotaContentDTM)
  content: FreightQuotaContentDTM[];

  @IsDefined()
  @ValidateNested()
  @Type(() => FreightQuotaPageableDTM)
  pageable: FreightQuotaPageableDTM;

  @IsDefined()
  @IsBoolean()
  last: boolean;

  @IsDefined()
  @IsNumber()
  totalPages: number;

  @IsDefined()
  @IsNumber()
  totalElements: number;

  @IsDefined()
  @IsNumber()
  size: number;

  @IsDefined()
  @IsNumber()
  number: number;

  @IsDefined()
  @IsObject()
  sort: {
    empty: boolean
    sorted: boolean
    unsorted: boolean
  };

  @IsDefined()
  @IsBoolean()
  first: boolean;

  @IsDefined()
  @IsNumber()
  numberOfElements: number;

  @IsDefined()
  @IsBoolean()
  empty: boolean;
}

export interface IRFRQuotasDTM {
  content?: FreightRFRQuotaContentDTM[]
  pageable: FreightQuotaPageableDTM
  last: boolean
  totalPages: number
  totalElements: number
  size: number
  number: number
  sort: {
    empty: boolean
    sorted: boolean
    unsorted: boolean
  },
  first: boolean
  numberOfElements: number
  empty: boolean
}

export class RFRQuotasDTM extends BaseDTM<IRFRQuotasDTM> {
  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => FreightRFRQuotaContentDTM)
  content: FreightRFRQuotaContentDTM[];

  @IsDefined()
  @ValidateNested()
  @Type(() => FreightQuotaPageableDTM)
  pageable: FreightQuotaPageableDTM;

  @IsDefined()
  @IsBoolean()
  last: boolean;

  @IsDefined()
  @IsNumber()
  totalPages: number;

  @IsDefined()
  @IsNumber()
  totalElements: number;

  @IsDefined()
  @IsNumber()
  size: number;

  @IsDefined()
  @IsNumber()
  number: number;

  @IsDefined()
  @IsObject()
  sort: {
    empty: boolean
    sorted: boolean
    unsorted: boolean
  };

  @IsDefined()
  @IsBoolean()
  first: boolean;

  @IsDefined()
  @IsNumber()
  numberOfElements: number;

  @IsDefined()
  @IsBoolean()
  empty: boolean;
}

export interface IRFRQuotasOptionsDTM {
  last?: boolean
  totalPages?: number
  totalElements?: number
  size?: number
  page?: number
}

export class RFRQuotasOptionsDTM extends BaseDTM<IRFRQuotasOptionsDTM> {
  @IsOptional()
  @IsBoolean()
  last: boolean;

  @IsOptional()
  @IsNumber()
  totalPages: number;

  @IsOptional()
  @IsNumber()
  totalElements: number;

  @IsOptional()
  @IsNumber()
  size: number;

  @IsOptional()
  @IsNumber()
  page: number;
}

export interface IQuotaRFRRequestOptionsDTM {
  step1?: boolean;
  step2?: boolean;
  step3?: boolean;
  isLoading?: boolean;
  isOpenModalRate?: boolean;
  isCompletedRequest?: boolean;
  isIncludeRelatedPortRequest?: boolean;
  fromDate?: string;
  errorRateDate?: IDefaultFieldErrors;
  schedulesRequest?: FreightRFRQuotaScheduleDTM[];
  rateRequestParams?: FreightRFRQuotaContentParamsDTM;
}

export class QuotaRFRRequestOptionsDTM extends BaseDTM<IQuotaRFRRequestOptionsDTM> {
  @IsOptional()
  @IsBoolean()
  step1: boolean;

  @IsOptional()
  @IsBoolean()
  step2: boolean;

  @IsOptional()
  @IsBoolean()
  step3: boolean;

  @IsOptional()
  @IsBoolean()
  isLoading: boolean;

  @IsOptional()
  @IsBoolean()
  isOpenModalRate: boolean;

  @IsOptional()
  @IsBoolean()
  isCompletedRequest: boolean;

  @IsOptional()
  @IsBoolean()
  isIncludeRelatedPortRequest: boolean;

  @IsOptional()
  @IsString()
  fromDate?: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => DefaultFieldErrors)
  errorRateDate?: DefaultFieldErrors;

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => FreightRFRQuotaScheduleDTM)
  schedulesRequest: FreightRFRQuotaScheduleDTM[];

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightRFRQuotaContentParamsDTM)
  rateRequestParams: FreightRFRQuotaContentParamsDTM;
}
